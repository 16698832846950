import { useFetch } from "../hooks/useFetch";
import { useState, useEffect } from "react";

const AccountInfoUpdate = () => {
    const { api } = useFetch();
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        nickname: '',
        phoneNum0: '010',
        phoneNum1: '',
        phoneNum2: '',
        email: '',
        emailDomain: 'gmail.com',
        customEmail: '',
        agreeToSMS: false,
        agreeToEmailMarketing: false,
    });

    const [verification, setVerification] = useState({
        password: '',
        confirmPassword: '',
        nickname: '',
        phoneNum: '',
        email: '',
    });

    const [customEmailVisible, setCustomEmailVisible] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const { data, error } = await api({
                action: 'getUserInfo',
            });
            if (!error) {
                const phoneParts = data.phone_num ? data.phone_num.split('-') : ['010', '', ''];
                const emailParts = data.email ? data.email.split('@') : ['', 'gmail.com'];
                
                let emailDomain = emailParts[1];
                let customEmail = '';
                if (emailDomain !== 'gmail.com' && emailDomain !== 'naver.com' && emailDomain !== 'daum.net') {
                    emailDomain = 'Others';
                    customEmail = emailParts[1];
                }

                setFormData({
                    ...formData,
                    nickname: data.nickname || '',
                    phoneNum0: phoneParts[0],
                    phoneNum1: phoneParts[1],
                    phoneNum2: phoneParts[2],
                    email: emailParts[0],
                    emailDomain: emailDomain,
                    customEmail: customEmail,
                    agreeToSMS: data.sns_confirm || false,
                    agreeToEmailMarketing: data.email_confirm || false,
                });

                setCustomEmailVisible(emailDomain === 'Others');
            }
        };
        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;

        if (id === 'emailDomain') {
            setFormData({
                ...formData,
                emailDomain: value,
                customEmail: value === 'Others' ? formData.customEmail : '', 
            });
            setCustomEmailVisible(value === 'Others');
        } else {
            setFormData({
                ...formData,
                [id]: type === 'checkbox' ? checked : value
            });
        }

        validateInput(id, value);
    };

    const validateInput = (id, value) => {
        let errorMessage = '';
        switch (id) {
            case 'password':
                if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
                    errorMessage = '한글을 포함할 수 없습니다';
                } else if (value.length < 8) {
                    errorMessage = '8자 이상이어야 합니다';
                } else if (!/[A-Z]/.test(value)) {
                    errorMessage = '최소 한 개의 대문자가 포함되어야 합니다';
                } else if (!/[0-9]/.test(value)) {
                    errorMessage = '최소 한 개의 숫자가 포함되어야 합니다';
                } else if (!/[!@#$%^&*]/.test(value)) {
                    errorMessage = '최소 한 개의 특수문자가 포함되어야 합니다';
                }
                break;

            case 'confirmPassword':
                if (value !== formData.password) {
                    errorMessage = '비밀번호가 일치하지 않습니다';
                }
                break;
            case 'nickname':
                if (!value) {
                    errorMessage = '닉네임은 필수 입력 사항입니다';
                } else if (value.length < 2 || value.length > 12) {
                    errorMessage = '2~12자 이내로 입력하셔야 합니다';
                }
                break;
            case 'phoneNum1':
            case 'phoneNum2':
                if (!value) {
                    errorMessage = '전화번호는 필수 입력 사항입니다';
                } else if (!/^[0-9]+$/.test(value)) {
                    errorMessage = '숫자만 입력 가능합니다';
                }
                break;
            case 'email':
                if (!value) {
                    errorMessage = '이메일은 필수 입력 사항입니다';
                }
                break;
            default:
                break;
        }
        setVerification((prev) => ({ ...prev, [id]: errorMessage }));
        return errorMessage;
    };

    const onClick = async () => {
        const requiredFields = ['password', 'confirmPassword', 'nickname', 'phoneNum1', 'phoneNum2', 'email'];

        let isValid = true;

        requiredFields.forEach((field) => {
            if (validateInput(field, formData[field]) || !formData[field]) {
                isValid = false;
            }
        });

        if (!isValid) {
            console.error('All fields are required');
            return;
        }

        const {
            password,
            email,
            nickname,
            phoneNum1,
            phoneNum2,
            customEmail,
            emailDomain,
            agreeToSMS,
            agreeToEmailMarketing,
        } = formData;

        const { data, error } = await api({
            action: 'updateUserInfo',
            params: {
                password: password,
                nickname: nickname,
                email: emailDomain === 'Others' ? `${email}@${customEmail}` : `${email}@${emailDomain}`,
                phone_num: `010-${phoneNum1}-${phoneNum2}`,
                agreeToSMS: agreeToSMS,
                agreeToEmailMarketing: agreeToEmailMarketing,
            }
        });

        if (error) {
            console.error(error);
        } else {
            console.log('User information updated:', data);
        }
    };

    const inputStyle = `w-full border border-gray-300 p-2 mt-1 rounded focus:outline-none focus:border-[#2A2F5A]`;

    return (
        <div className="min-h-screen font-nanum flex flex-col p-2 mb-10">
            <div className="p-4">
                <h1 className="text-2xl font-bold text-center">회원정보 수정</h1>
                
                <div className="mt-6">
                    <label className="block text-sm font-medium">비밀번호</label>
                    <input
                        id="password"
                        type="password"
                        placeholder="비밀번호"
                        value={formData.password}
                        onChange={handleChange}
                        className={inputStyle}
                        autoComplete="new-password"
                    />
                    {verification.password && <div className="text-red-500 text-sm">{verification.password}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">비밀번호 확인</label>
                    <input
                        id="confirmPassword"
                        type="password"
                        placeholder="비밀번호 확인"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={inputStyle}
                        autoComplete="new-password"
                    />
                    {verification.confirmPassword && <div className="text-red-500 text-sm">{verification.confirmPassword}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">닉네임</label>
                    <input
                        id="nickname"
                        placeholder="닉네임"
                        value={formData.nickname}
                        onChange={handleChange}
                        className={inputStyle}
                    />
                    {verification.nickname && <div className="text-red-500 text-sm">{verification.nickname}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">전화번호</label>
                    <div className="flex space-x-2">
                        <input
                            id="phoneNum0"
                            value={formData.phoneNum0}
                            maxLength={3}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                            disabled
                        />
                        <span className="mx-2 my-auto">-</span>
                        <input
                            id="phoneNum1"
                            placeholder="0000"
                            value={formData.phoneNum1}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                        />
                        <span className="mx-2 my-auto">-</span>
                        <input
                            id="phoneNum2"
                            placeholder="0000"
                            value={formData.phoneNum2}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                        />
                    </div>
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">이메일</label>
                    <div className="flex space-x-2">
                        <input
                            id="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-[35%] ${inputStyle}`}
                        />
                        <span className="text-gray-700 my-auto">@</span>
                        {customEmailVisible ? (
                            <input
                                id="customEmail"
                                placeholder="Other domain"
                                value={formData.customEmail}
                                onChange={handleChange}
                                className={`w-[30%] ${inputStyle}`}
                            />
                        ) : (
                            <input
                                value={formData.emailDomain}
                                disabled
                                className={`w-[30%] ${inputStyle}`}
                            />
                        )}
                        <select
                            id="emailDomain"
                            value={formData.emailDomain}
                            onChange={handleChange}
                            className={`w-[30%] ${inputStyle}`}
                        >
                            <option value="gmail.com">gmail.com</option>
                            <option value="naver.com">naver.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                    {verification.email && <div className="text-red-500 text-sm">{verification.email}</div>}
                </div>
            </div>

            <div className="p-4">
                <div className="mt-4">
                    <div className="flex flex-col space-y-2 text-sm ">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToSMS"
                                checked={formData.agreeToSMS}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>SMS 마케팅 정보 수신에 동의합니다. (선택)</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToEmailMarketing"
                                checked={formData.agreeToEmailMarketing}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>이메일 마케팅 정보 수신에 동의합니다. (선택)</span>
                        </label>
                    </div>
                </div>

                <div className="mt-10">
                    <button
                        onClick={onClick}
                        className="w-full bg-slate-700 text-white py-3 rounded"
                    >
                        수정하기
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AccountInfoUpdate;
