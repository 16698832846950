import { useFetch } from "../hooks/useFetch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const { api } = useFetch();
    const [formData, setFormData] = useState({
        userId: '',
        password: '',
        confirmPassword: '',
        name: '',
        nickname: '',
        phoneNum0: '010',
        phoneNum1: '',
        phoneNum2: '',
        email: '',
        emailDomain: 'gmail.com',
        customEmail: '',
        agreeToTerms: false,
        agreeToPrivacy: false,
        agreeToSMS: false,
        agreeToEmailMarketing: false,
    });
    const [verification, setVerification] = useState({
        userId: '',
        password: '',
        confirmPassword: '',
        nickname: '',
        phoneNum: '',
        email: '',
    });
    const [customEmailVisible, setCustomEmailVisible] = useState(false);
    const navigate = useNavigate()

    const validateInput = (id, value) => {
        let errorMessage = '';
        switch (id) {
            case 'userId':
                if (!value) {
                    errorMessage = '아이디는 필수 입력 사항입니다';
                } else if (value.length < 4) {
                    errorMessage = '최소 4자 이상이어야 합니다';
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    errorMessage = '영문자와 숫자만 입력 가능합니다';
                }
                break;
            case 'password':
                if (!value) {
                    errorMessage = '비밀번호는 필수 입력 사항입니다';
                } else if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
                    errorMessage = '한글을 포함할 수 없습니다';
                } else if (value.length < 8) {
                    errorMessage = '8자 이상이어야 합니다';
                } else if (!/[a-z]/.test(value) || !/[A-Z]/.test(value) || !/[!@#$%^&*]/.test(value)) {
                    errorMessage = '최소 한 개의 대문자, 특수문자가 포함되어야 합니다';
                } 
                break;
            case 'confirmPassword':
                if (value !== formData.password) {
                    errorMessage = '비밀번호가 일치하지 않습니다';
                }
                break;
            case 'nickname':
                if (!value) {
                    errorMessage = '닉네임은 필수 입력 사항입니다';
                } else if (value.length < 2 || value.length > 12) {
                    errorMessage = '2~12자 이내로 입력하셔야 합니다';
                }
                break;
            case 'phoneNum1':
            case 'phoneNum2':
                if (!value) {
                    errorMessage = '전화번호는 필수 입력 사항입니다';
                } else if (!/^[0-9]+$/.test(value)) {
                    errorMessage = '숫자만 입력 가능합니다';
                }
                break;
            case 'email':
                if (!value) {
                    errorMessage = '이메일은 필수 입력 사항입니다';
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    errorMessage = '영문자와 숫자만 입력 가능합니다';
                }
                break;
            default:
                break;
        }
        setVerification((prev) => ({ ...prev, [id]: errorMessage }));
        return errorMessage;
    };

    const checkDuplicate = async (id, value) => {
        if (!value) return '';
        try {
            const params = id === 'email'
                ? { email: value }
                : { [id]: value };
            const { data, error } = await api({
                action: "isDuplicate",
                params: params,
            });
            if (!error) {
                if (data.result === "not_found") {
                    return '';
                }
                return `${id === "userId" ? "아이디" : id === "email" ? "이메일" : "닉네임"}가 중복되었습니다.`;
            }
        } catch (err) {
            console.error("Error checking duplicate:", err);
        }
        return '중복 여부 확인에 실패했습니다.';
    };

    const handleBlur = async (e) => {
        const { id, value } = e.target;

        if (['userId', 'email', 'nickname'].includes(id)) {
            const fullEmail = id === 'email' ? `${formData.email}@${formData.customEmail || formData.emailDomain}` : value;
            const errorMessage = await checkDuplicate(id, fullEmail);
            setVerification((prev) => ({ ...prev, [id]: errorMessage }));
        }
    };

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;

        if (id === 'emailDomain') {
            setFormData({
                ...formData,
                [id]: value,
                emailDomain: value
            });
            if (value === 'gmail.com' || value === 'naver.com' || value === 'daum.net' || value === 'custom') {
                setCustomEmailVisible(false);
            } else {
                setCustomEmailVisible(true);
            }
        } else if (id === 'masterAgreement') {
            const masterChecked = checked;
            setFormData({
                ...formData,
                agreeToTerms: masterChecked,
                agreeToPrivacy: masterChecked,
                agreeToSMS: masterChecked,
                agreeToEmailMarketing: masterChecked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: type === 'checkbox' ? checked : value
            });
        }

        validateInput(id, value);
    };

    const onClick = async () => {
        const requiredFields = [
            'userId',
            'password',
            'confirmPassword',
            'name',
            'nickname',
            'phoneNum1',
            'phoneNum2',
            'email',
            'agreeToTerms',
            'agreeToPrivacy'
        ];

        let isValid = true;

        requiredFields.forEach((field) => {
            if (validateInput(field, formData[field]) || !formData[field]) {
                isValid = false;
            }
        });

        if (!isValid) {
            console.error('All fields are required');
            return;
        }

        const {
            userId,
            password,
            email,
            name,
            nickname,
            phoneNum1,
            phoneNum2,
            customEmail,
            emailDomain,
            agreeToSMS,
            agreeToEmailMarketing,
        } = formData;

        const { data, error } = await api({
            action: 'signUp',
            params: {
                userId: userId,
                password: password,
                name: name,
                nickname: nickname,
                email: customEmail ? `${email}@${customEmail}` : `${email}@${emailDomain}`,
                phoneNum: `010-${phoneNum1}-${phoneNum2}`,
                agreeToSMS: agreeToSMS,
                agreeToEmailMarketing: agreeToEmailMarketing,
            }
        });

        console.log(data, error);

        if (!error) {
            alert('회원가입 되었습니다.')
            navigate('/')
        } else {
            alert(error)
        }
    };

    const inputStyle = `w-full border border-gray-300 p-2 mt-1 rounded focus:outline-none focus:border-[#2A2F5A]`;

    return (
        <div className="min-h-screen font-nanum flex flex-col p-2 mb-10">
            <div className="p-4">
                <h1 className="text-2xl font-bold text-center">회원가입</h1>
                
                <div className="mt-6">
                    <label className="block text-sm font-medium">아이디</label>
                    <input
                        id="userId"
                        placeholder="아이디"
                        value={formData.userId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={inputStyle}
                        autoComplete="new-password"
                    />
                    {verification.userId && <div className="text-red-500 text-sm">{verification.userId}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">비밀번호</label>
                    <input
                        id="password"
                        type="password"
                        placeholder="비밀번호"
                        value={formData.password}
                        onChange={handleChange}
                        className={inputStyle}
                        autoComplete="new-password"
                    />
                    {verification.password && <div className="text-red-500 text-sm">{verification.password}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">비밀번호 확인</label>
                    <input
                        id="confirmPassword"
                        type="password"
                        placeholder="비밀번호 확인"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={inputStyle}
                        autoComplete="new-password"
                    />
                    {verification.confirmPassword && <div className="text-red-500 text-sm">{verification.confirmPassword}</div>}
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">이름</label>
                    <input
                        id="name"
                        placeholder="이름"
                        value={formData.name}
                        onChange={handleChange}
                        className={inputStyle}
                    />
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">닉네임</label>
                    <input
                        id="nickname"
                        placeholder="닉네임"
                        value={formData.nickname}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        className={inputStyle}
                    />
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">전화번호</label>
                    <div className="flex space-x-2">
                        <input
                            id="phoneNum0"
                            value={formData.phoneNum0}
                            maxLength={3}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                        />
                        <span className="mx-2 my-auto">-</span>
                        <input
                            id="phoneNum1"
                            placeholder="0000"
                            value={formData.phoneNum1}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                        />
                        <span className="mx-2 my-auto">-</span>
                        <input
                            id="phoneNum2"
                            placeholder="0000"
                            value={formData.phoneNum2}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center ${inputStyle}`}
                        />
                    </div>
                </div>

                <div className="mt-4">
                    <label className="block text-sm font-medium">이메일</label>
                    <div className="flex space-x-2">
                        <input
                            id="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-[45%] ${inputStyle}`}
                        />
                        <span className="text-gray-700 my-auto">@</span>
                        <input
                            id="customEmail"
                            placeholder="Other domain"
                            value={customEmailVisible ? formData.customEmail : formData.emailDomain}
                            onChange={handleChange}
                            className={`w-[45%] ${inputStyle}`}
                            disabled={!customEmailVisible}
                        />
                        {!customEmailVisible && (
                            <select
                                id="emailDomain"
                                value={formData.emailDomain}
                                onChange={handleChange}
                                className={`w-[45%] ${inputStyle}`}
                            >
                                <option value="gmail.com">gmail.com</option>
                                <option value="naver.com">naver.com</option>
                                <option value="daum.net">daum.net</option>
                                <option value="custom">Others</option>
                            </select>
                        )}
                    </div>
                    {verification.email && <div className="text-red-500 text-sm">{verification.email}</div>}
                </div>
                
            </div>

            <div className="p-4">
                <div className="mt-4">
                    <div className="flex flex-col space-y-2 text-sm ">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="masterAgreement"
                                checked={
                                    formData.agreeToTerms &&
                                    formData.agreeToPrivacy &&
                                    formData.agreeToSMS &&
                                    formData.agreeToEmailMarketing
                                }
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>모든 약관에 동의합니다.</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToTerms"
                                checked={formData.agreeToTerms}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>이용약관에 동의합니다. (필수)</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToPrivacy"
                                checked={formData.agreeToPrivacy}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>개인정보 수집 및 이용에 동의합니다. (필수)</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToSMS"
                                checked={formData.agreeToSMS}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>SMS 마케팅 정보 수신에 동의합니다. (선택)</span>
                        </label>
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                id="agreeToEmailMarketing"
                                checked={formData.agreeToEmailMarketing}
                                onChange={handleChange}
                                className="mr-2"
                            />
                            <span>이메일 마케팅 정보 수신에 동의합니다. (선택)</span>
                        </label>
                    </div>
                </div>

                <div className="mt-10">
                    <button
                        onClick={onClick}
                        className="w-full bg-slate-700 text-white py-3 rounded"
                    >
                        회원가입
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SignUp;
