import { useMediaQuery } from 'react-responsive';
import DesktopRoutes from "./DesktopRoutes";
import MobileRoutes from "./MobileRoutes";


const App = () => {
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

	return (
		<>
			{isMobile ? <MobileRoutes /> : <DesktopRoutes />}
		</>
	);
};

export default App
