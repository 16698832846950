import { useState, useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { useFetch } from "../../hooks/useFetch";


export const SimpleCartTable = ({ cartData }) => {

    // const handleAllCheckedChange = (e) => {
    //     const checked = e.target.checked;
    //     setCartData(cartData.map(item => ({ ...item, checked })));
    // };
    
    // const handleItemCheckedChange = (index, checked) => {
    //     setCartData(cartData.map((item, i) =>
    //         i === index ? { ...item, checked } : item
    //     ));
    // };

    return (
        <div>
            <hr className={`h-[2.5px] bg-gray-800 mt-10`}/>

            <div className={`flex flex-row justify-between mx-auto w-full py-3 text-sm font-bold bg-zinc-100 `}>
                <div className="flex flex-row w-[45%] justify-center ">상품정보</div>
                <div className="flex flex-row w-[15%] justify-center ">수량</div>
                <div className="flex flex-row w-[20%] justify-center ">주문금액</div>
            </div>

            <div className="w-full flex flex-col justify-start">
                {cartData ? ( cartData.map((elem, index) => {

                    const { item: { item_name: name, image_urls: img}, quantity, options, total_price: totalPrice } = elem

                    return (
                        <div className={`flex flex-row justify-between w-full h-36 border-t border-gray-600 `} key={index}>

                            <div className="flex flex-row w-[45%] justify-start">
                                <img className=" h-[80%] my-auto ml-5" alt={`${name}`} src={img[0]} />
                                
                                <div className="my-auto ml-7">
                                    <div className="font-bold">{name}</div>
                                    <div className="text-sm text-gray-800">{options}</div>
                                </div>
                            </div>

                            <div className="flex justify-center w-[20%] my-auto">
                                {quantity}
                            </div>

                            <div className="flex justify-center w-[20%] my-auto">
                                ₩ {(totalPrice).toLocaleString('ko-KR')}
                            </div>
                            
                        </div>
                    )
                })) : ( 
                <div className="flex h-48 justify-center ">
                    <div className="flex flex-col justify-center ">
                        장바구니가 비어있습니다.
                    </div>
                </div>
                )}
                
            </div>

            <hr className={`h-[2.5px] bg-gray-800`}/>
        </div>
    )

}


const Cart = () => {
    const navigate = useNavigate();
    const { api } = useFetch();
    const cartRef = useRef(null);
    const [isOpen, setIsOpen] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [cartData, setCartData] = useState([]);
    const [selectedItem, setSelectedItem] = useState(null);
    const [newQuantity, setNewQuantity] = useState(1);
    const [itemOptions, setItemOptions] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [optionLimits, setOptionLimits] = useState({}); // 여러 상품별 옵션 제한 저장
    const [isModalOpen, setIsModalOpen] = useState({ quantity: false, option: false });

    useEffect(() => {
        const fetchCart = async () => {
            try {
                const { data, error } = await api({ action: "getCart" });
                if (error) {
                    console.error(error);
                } else {
                    setCartData(data.map(obj => ({ ...obj, checked: true })));
                }
            } catch (err) {
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        };
        fetchCart();
    }, []);

    const handleItemChange = async (item, type) => {
        setSelectedItem(item);

        // getItem API 호출하여 해당 상품의 옵션별 최대 수량 가져오기
        const { data, error } = await api({
            action: "getItem",
            query: item.item.item_id,
        });

        if (error) {
            console.error(error);
            return;
        }

        // 해당 상품의 옵션별 수량을 optionLimits에 저장
        setOptionLimits(prevLimits => ({
            ...prevLimits,
            [item.item.item_id]: data.options // 해당 상품의 item_id를 key로 사용하여 옵션 제한 저장
        }));

        if (type === "quantity") {
            setNewQuantity(item.quantity);
            setIsModalOpen({ ...isModalOpen, quantity: true });
        } else if (type === "option") {
            setItemOptions(Object.keys(data.options)); // 옵션 목록 저장
            setSelectedOption(item.options); // 현재 선택된 옵션
            setIsModalOpen({ ...isModalOpen, option: true });
        }
    };

    const saveItemChange = async (type) => {
        if (selectedItem) {
            let updateParams = {
                quantity: newQuantity,
                options: selectedOption,
            };
            if (type === "quantity") {
                updateParams.options = selectedItem.options;
            } else if (type === "option") {
                updateParams.quantity = selectedItem.quantity;
            }
            await api({
                action: "updateCart",
                query: `${selectedItem.cart_id}`,
                params: updateParams,
            });
            setCartData(cartData.map(item => 
                item.cart_id === selectedItem.cart_id 
                ? { ...item, quantity: newQuantity, options: selectedOption } 
                : item
            ));
            setIsModalOpen({ ...isModalOpen, [type]: false });
        }
    };

    const handleQuantityChange = (increment) => {
        const currentItemLimits = optionLimits[selectedItem.item.item_id]; // 현재 선택된 상품의 옵션 제한
        const limit = currentItemLimits[selectedItem.options]; // 선택된 옵션의 최대 수량

        if (increment && newQuantity < limit) {
            setNewQuantity(prev => prev + 1);
        } else if (!increment && newQuantity > 1) {
            setNewQuantity(prev => prev - 1);
        } else if (increment && newQuantity >= limit) {
            alert(`해당 옵션은 최대 ${limit}개까지만 담을 수 있습니다.`);
        }
    };

    const Modal = ({ isOpen, onClose, onSave, title, children }) => {
        if (!isOpen) return null;

        const handleBackgroundClick = (e) => {
            if (e.target === e.currentTarget) {
                onClose();
            }
        };

        return (
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50" onClick={handleBackgroundClick}>
                <div className="bg-white p-8 rounded-lg shadow-lg w-80 max-w-lg">
                    <h2 className="text-2xl font-bold mb-4">{title}</h2>
                    {children}
                    <div className="flex justify-end mt-6">
                        <button className="px-4 py-2 bg-gray-300 rounded mr-3" onClick={onClose}>취소</button>
                        <button className="px-4 py-2 bg-slate-700 text-white rounded" onClick={onSave}>저장</button>
                    </div>
                </div>
            </div>
        );
    };

    const CartTable = () => {
        const handleAllCheckedChange = (e) => {
            const checked = e.target.checked;
            setCartData(cartData.map(item => ({ ...item, checked })));
        };

        const handleItemCheckedChange = (index, checked) => {
            setCartData(cartData.map((item, i) => 
                i === index ? { ...item, checked } : item
            ));
        };

        return (
            <div className="font-nanum">
                <hr className="h-[2.5px] bg-zinc-200 mt-10" />
                <div className="flex flex-row justify-between mx-auto w-full py-3 text-sm font-bold bg-zinc-100">
                    <div className="flex flex-row w-[3%] justify-center ml-3">
                        <input type="checkbox" className="h-4 w-4 my-auto"
                            checked={cartData.length > 0 && cartData.every(item => item.checked)}
                            onChange={handleAllCheckedChange} />
                    </div>
                    <div className="flex flex-row w-[45%] justify-center">상품정보</div>
                    <div className="flex flex-row w-[10%] justify-center">수량</div>
                    <div className="flex flex-row w-[10%] justify-center">주문금액</div>
                    <div className="flex flex-row w-[20%] justify-center">주문관리</div>
                </div>
                <div className="w-full flex flex-col justify-start">
                    {cartData.length > 0 ? cartData.map((cart, index) => {
                        const { item, options, quantity, checked, total_price: totalPrice } = cart;
                        const { item_name: itemName, image_urls: images } = item;

                        return (
                            <div className="flex flex-row justify-between w-full h-36 border-t" key={index}>
                                <div className="flex flex-row w-[3%] justify-center ml-3">
                                    <input type="checkbox" className="h-4 w-4 my-auto"
                                        checked={checked}
                                        onChange={() => handleItemCheckedChange(index, !checked)} />
                                </div>
                                <div className="flex flex-row w-[45%] justify-start">
                                    <img className="h-[80%] my-auto ml-5" alt={itemName} src={images[0]} />
                                    <div className="my-auto ml-7">
                                        <div className="font-bold">{itemName}</div>
                                        <div className="text-sm text-gray-800">{options}</div>
                                    </div>
                                </div>
                                <div className="flex justify-center w-[10%] my-auto">{quantity}</div>
                                <div className="flex justify-center w-[10%] my-auto">₩ {(totalPrice).toLocaleString('ko-KR')}</div>
                                <div className="flex justify-center w-[20%] my-auto">
                                    <button onClick={() => handleItemChange(cart, "quantity")}>수량변경</button> / 
                                    <button onClick={() => handleItemChange(cart, "option")}>옵션변경</button>
                                </div>
                            </div>
                        );
                    }) : (
                        <div className="flex h-48 justify-center">
                            <div className="flex flex-col justify-center">장바구니가 비어있습니다.</div>
                        </div>
                    )}
                </div>
                <hr className="h-[2.5px] bg-zinc-300" />
            </div>
        );
    };

    return (
        <div className="flex flex-col justify-start h-1/3 mb-16 pb-28" id="cart" ref={cartRef}>
            <div className="font-bold font-raleway text-2xl">
                CART
                <FontAwesomeIcon icon={isOpen ? faAngleDoubleUp : faAngleDoubleDown} className="ml-2 cursor-pointer"
                    onClick={() => setIsOpen(!isOpen)} />
            </div>
            <div className={`${isOpen ? "" : "hidden"}`}>
                {isLoading ? null : (
                    <>
                        <CartTable />
                        <div className="flex flex-row justify-end mt-4 font-nanum">
                            <button className="mr-3 px-4 py-2 bg-gray-300 rounded"
                                onClick={async () => {
                                    const filteredCartData = cartData.filter(item => !item.checked);
                                    const checkedCartIds = cartData.filter(cart => cart.checked).map(cart => cart.cart_id);
                                    await Promise.all(checkedCartIds.map(id => api({
                                        action: "removeCart",
                                        params: { cartId: id },
                                        query: `/${id}`
                                    })));
                                    setCartData(filteredCartData);
                                }}>삭제</button>
                            <button className="px-4 py-2 bg-slate-700 text-white rounded"
                                onClick={() => {
                                    const filteredCartData = cartData.filter(item => item.checked);
                                    navigate('/order', { state: { _cartData: filteredCartData, orderType: "cart" } });
                                }}>주문하기</button>
                        </div>
                    </>
                )}
            </div>

            {/* 수량변경 모달 */}
            <Modal
                isOpen={isModalOpen.quantity}
                onClose={() => setIsModalOpen({ ...isModalOpen, quantity: false })}
                onSave={() => saveItemChange("quantity")}
                title="수량 변경"
            >
                <div className="flex items-center justify-center">
                    <button onClick={() => handleQuantityChange(false)} disabled={newQuantity <= 1}
                        className="px-3 py-2 bg-gray-200 rounded">-</button>
                    <span className="mx-6 text-lg">{newQuantity}</span>
                    <button onClick={() => handleQuantityChange(true)}
                        className="px-3 py-2 bg-gray-200 rounded">+</button>
                </div>
            </Modal>

            {/* 옵션변경 모달 */}
            <Modal
                isOpen={isModalOpen.option}
                onClose={() => setIsModalOpen({ ...isModalOpen, option: false })}
                onSave={() => saveItemChange("option")}
                title="옵션 변경"
            >
                <div className="flex flex-col items-center">
                    <select value={selectedOption} onChange={(e) => setSelectedOption(e.target.value)}
                        className="px-4 py-2 bg-gray-200 rounded w-full mb-4">
                        {itemOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                        ))}
                    </select>
                </div>
            </Modal>
        </div>
    );
};


export default Cart