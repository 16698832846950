import { useDispatch } from "react-redux";
import { loginSuccess, logoutSuccess } from "../store/reducers";
import { cookies } from "../utils/tokenStorage";
import { useFetch } from "../hooks/useFetch"

export const useAuth = () => {

    const dispatch = useDispatch()
    const { api } = useFetch()

    const refreshToken = async () => {
        const refreshToken = cookies.get("refresh");
        if (!refreshToken) {
            console.error("No refresh token found");
            return;
        }
        const { data, error } = await api({
            action: "refreshToken",
            params: {
                refresh_token: refreshToken,
            },
        });
        if (!error) {
            cookies.set("access", data.access_token);
            if (data.refresh_token) {
                cookies.set("refresh", data.refresh_token);
            }
            await fetchUserFromToken()
        } else {
            console.error(error);
            alert("로그인 정보가 만료되었습니다.")
            logout()
        }
    };

    const fetchUserFromToken = async () => {

        const { data, error } = await api({
            action: 'getUserInfo',
        })
        if (!error) {
            dispatch(loginSuccess({
                id: data.id,
                name: data.name,
                nickname: data.nickname
            }))
        } else {
            console.error(error)
            refreshToken()
        }
    }

    const login = async ({ id, password }) => {

        try {
            const { data, error } = await api({
                action: 'signIn',
                params: {
                    id: id,
                    password: password
                }
            });

            if (!error){
                console.log("login success", data)

                cookies.set("access", data.token.access_token)
                cookies.set("refresh", data.token.refresh_token)

                dispatch(loginSuccess({
                    id: data.id,
                    name: data.name,
                    nickname: data.nickname
                }))

                return { success:data, error:null}
            } else {
                console.log("error", error)
                return { success:null, error:error }
            }

            
        } catch (err) {
            console.error(err)
            return err
        }
    }

   

    const logout = () => {
        cookies.remove("access");
        cookies.remove("refresh");
        dispatch(logoutSuccess());
    }

    return { login, logout, fetchUserFromToken, refreshToken };
};