import { useFetch } from "../hooks/useFetch";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const SignUp = () => {
    const { api } = useFetch();
    const [formData, setFormData] = useState({
        userId: '',
        password: '',
        confirmPassword: '',
        name: '',
        nickname: '',
        phoneNum0: '010',
        phoneNum1: '',
        phoneNum2: '',
        email: '',
        emailDomain: 'gmail.com',
        customEmail: '',
        agreeToTerms: false,
        agreeToPrivacy: false,
        agreeToSMS: false,
        agreeToEmailMarketing: false,
    });
    const [verification, setVerification] = useState({
        userId: '',
        password: '',
        confirmPassword: '',
        nickname: '',
        email: '',
    });
    const [customEmailVisible, setCustomEmailVisible] = useState(false);
    const navigate = useNavigate()

    const checkDuplicate = async (id, value) => {
        if (!value) return '';
        try {
            const params = id === 'email'
                ? { email: value }
                : { [id]: value };
            const { data, error } = await api({
                action: "isDuplicate",
                params: params,
            });
            if (!error) {
                if (data.result === "not_found") {
                    return '';
                }
                return `${id === "userId" ? "아이디" : id === "email" ? "이메일" : "닉네임"}가 중복되었습니다.`;
            }
        } catch (err) {
            console.error("Error checking duplicate:", err);
        }
        return '중복 여부 확인에 실패했습니다.';
    };

    const validateInput = (id, value) => {
        let errorMessage = '';
        switch (id) {
            case 'userId':
                if (!value) {
                    errorMessage = '아이디는 필수 입력 사항입니다';
                } else if (value.length < 4) {
                    errorMessage = '최소 4자 이상이어야 합니다';
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    errorMessage = '영문자와 숫자만 입력 가능합니다';
                }
                break;
            case 'password':
                if (!value) {
                    errorMessage = '비밀번호는 필수 입력 사항입니다';
                } else if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
                    errorMessage = '한글을 포함할 수 없습니다';
                } else if (value.length < 8) {
                    errorMessage = '8자 이상이어야 합니다';
                } else if (!/[A-Z]/.test(value)) {
                    errorMessage = '최소 한 개의 대문자가 포함되어야 합니다';
                } else if (!/[0-9]/.test(value)) {
                    errorMessage = '최소 한 개의 숫자가 포함되어야 합니다';
                } else if (!/[!@#$%^&*]/.test(value)) {
                    errorMessage = '최소 한 개의 특수문자가 포함되어야 합니다';
                }
                break;
            case 'confirmPassword':
                if (value !== formData.password) {
                    errorMessage = '비밀번호가 일치하지 않습니다';
                }
                break;
            case 'nickname':
                if (!value) {
                    errorMessage = '닉네임은 필수 입력 사항입니다';
                } else if (value.length < 2 || value.length > 12) {
                    errorMessage = '2~12자 이내로 입력하셔야 합니다';
                }
                break;
            case 'email':
                if (!value) {
                    errorMessage = '이메일은 필수 입력 사항입니다';
                } else if (!/^[a-zA-Z0-9]+$/.test(value)) {
                    errorMessage = '영문자와 숫자만 입력 가능합니다';
                }
                break;
            default:
                break;
        }
        setVerification((prev) => ({ ...prev, [id]: errorMessage }));
    };

    const handleBlur = async (e) => {
        const { id, value } = e.target;

        if (['userId', 'email', 'nickname'].includes(id)) {
            const fullEmail = id === 'email' ? `${formData.email}@${formData.customEmail || formData.emailDomain}` : value;
            const errorMessage = await checkDuplicate(id, fullEmail);
            setVerification((prev) => ({ ...prev, [id]: errorMessage }));
        }
    };

    const handleDomainChange = async (e) => {
        const { value } = e.target;
        setFormData({
            ...formData,
            emailDomain: value,
            customEmail: value !== 'gmail.com' && value !== 'naver.com' && value !== 'daum.net' ? formData.customEmail : '',
        });

        // 이메일 도메인이 변경되었을 때 중복 확인
        if (formData.email) {
            const fullEmail = `${formData.email}@${value === 'custom' ? formData.customEmail : value}`;
            const errorMessage = await checkDuplicate('email', fullEmail);
            setVerification((prev) => ({ ...prev, email: errorMessage }));
        }

        setCustomEmailVisible(value === 'custom');
    };

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;

        if (id === 'emailDomain') {
            handleDomainChange(e);
        } else if (id === 'masterAgreement') {
            const masterChecked = checked;
            setFormData({
                ...formData,
                agreeToTerms: masterChecked,
                agreeToPrivacy: masterChecked,
                agreeToSMS: masterChecked,
                agreeToEmailMarketing: masterChecked,
            });
        } else {
            setFormData({
                ...formData,
                [id]: type === 'checkbox' ? checked : value
            });
            validateInput(id, value); // 입력값 검증 처리
        }
    };

    const onClick = async () => {
        const requiredFields = [
            'userId',
            'password',
            'confirmPassword',
            'name',
            'nickname',
            'phoneNum1',
            'phoneNum2',
            'email',
            'agreeToTerms',
            'agreeToPrivacy'
        ];

        let isValid = true;

        for (const field of requiredFields) {
            const validationError = verification[field];
            if (validationError) {
                isValid = false;
                break;
            }
        }

        if (!isValid) {
            console.error('All fields are required');
            return;
        }

        const {
            userId,
            password,
            email,
            name,
            nickname,
            phoneNum1,
            phoneNum2,
            customEmail,
            emailDomain,
            agreeToSMS,
            agreeToEmailMarketing,
        } = formData;

        const { data, error } = await api({
            action: 'signUp',
            params: {
                userId: userId,
                password: password,
                name: name,
                nickname: nickname,
                email: customEmail ? `${email}@${customEmail}` : `${email}@${emailDomain}`,
                phoneNum: `010-${phoneNum1}-${phoneNum2}`,
                agreeToSMS: agreeToSMS,
                agreeToEmailMarketing: agreeToEmailMarketing,
            }
        });

        console.log(data, error);

        if (!error) {
            alert('회원가입 되었습니다.')
            navigate('/')
        } else {
            alert(error)
        }
    };

    const elemDivStyle = `mb-2 h-20 flex flex-col items-left justify-between`;
    const labelStyle = `flex flex-row justify-between h-7`;
    const inputStyle = `p-2 border border-gray-500 placeholder:italic h-12`;

    return (
        <div className="w-full h-[160vh] 2xl:h-[135vh] min-h-[1600px] pt-28 2xl:pt-32 2xl:pb-10 flex flex-row justify-center bg-white font-nanum">
            <div className="flex flex-col justify-start w-[43%] 2xl:w-[33%] h-[85%] 2xl:h-[75%] bg-white px-5 py-3 lg:p-16 shadow-md border-gray-500 border-2">
                <h1 className="text-2xl lg:text-3xl font-bold mb-10 2xl:mb-14 text-center font-raleway">Sign Up</h1>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="userId" className="text-gray-700">아이디</label>
                        {verification.userId && <div className="text-red-500 text-sm">{verification.userId}</div>}
                    </div>
                    <input
                        id="userId"
                        placeholder="아이디"
                        value={formData.userId}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${inputStyle}`}
                        autoComplete="new-password"
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="password" className="text-gray-700">비밀번호</label>
                        {verification.password && <div className="text-red-500 text-sm">{verification.password}</div>}
                    </div>
                    <input
                        id="password"
                        type="password"
                        placeholder="비밀번호"
                        value={formData.password}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                        autoComplete="new-password"
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="confirmPassword" className="text-gray-700">비밀번호 확인</label>
                        {verification.confirmPassword && <div className="text-red-500 text-sm">{verification.confirmPassword}</div>}
                    </div>
                    <input
                        id="confirmPassword"
                        type="password"
                        placeholder="비밀번호 확인"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                        autoComplete="new-password"
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="nickname" className="text-gray-700">닉네임</label>
                        {verification.nickname && <div className="text-red-500 text-sm">{verification.nickname}</div>}
                    </div>
                    <input
                        id="nickname"
                        placeholder="닉네임"
                        value={formData.nickname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={`${inputStyle}`}
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <label htmlFor="name" className="text-gray-700">이름</label>
                    <input
                        id="name"
                        placeholder="이름"
                        value={formData.name}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label className="text-gray-700">전화번호</label>
                        {(verification.phoneNum1 || verification.phoneNum2) && <div className="text-red-500 text-sm">{verification.phoneNum1 || verification.phoneNum2}</div>}
                    </div>
                    
                    <div className={`flex flex-row justify-between h-[60%] items-center`}>
                        <input
                            id="phoneNum0"
                            value={formData.phoneNum0}
                            maxLength={3}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="mx-2">-</span>
                        <input
                            id="phoneNum1"
                            placeholder="0000"
                            value={formData.phoneNum1}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="mx-2">-</span>
                        <input
                            id="phoneNum2"
                            placeholder="0000"
                            value={formData.phoneNum2}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                    </div>
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="email" className="text-gray-700">이메일</label>
                        {verification.email && <div className="text-red-500 text-sm">{verification.email}</div>}
                    </div>
                    <div className={`flex flex-row justify-between h-[60%] items-center`}>
                        <input
                            id="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className={`w-[35%] text-left p-2 px-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="text-gray-700 mx-1 my-auto 2xl:text-lg">@</span>
                        <input
                            id="customEmail"
                            placeholder="Other domain"
                            value={customEmailVisible ? formData.customEmail : formData.emailDomain}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="w-[30%] p-2 mr-1 border border-gray-500 placeholder:italic h-full"
                        />
                        <select
                            id="emailDomain"
                            value={formData.emailDomain}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="flex border border-gray-500 w-[30%] p-2 h-full"
                        >
                            <option value="gmail.com">gmail.com</option>
                            <option value="naver.com">naver.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="custom">Others</option>
                        </select>
                    </div>
                </div>

                <div className="mt-5 flex flex-col items-start">
                    <div className="flex flex-row">
                        <input
                            type="checkbox"
                            id="masterAgreement"
                            checked={
                                formData.agreeToTerms &&
                                formData.agreeToPrivacy &&
                                formData.agreeToSMS &&
                                formData.agreeToEmailMarketing
                            }
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="masterAgreement" className="text-sm text-gray-500">모든 약관에 동의합니다.</label>
                    </div>
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToTerms"
                            checked={formData.agreeToTerms}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToTerms" className="text-sm text-gray-500">이용약관에 동의합니다. (필수)</label>
                    </div>
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToPrivacy"
                            checked={formData.agreeToPrivacy}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToPrivacy" className="text-sm text-gray-500">개인정보 수집 및 이용에 동의합니다. (필수)</label>
                    </div>
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToSMS"
                            checked={formData.agreeToSMS}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToSMS" className="text-sm text-gray-500">SMS 마케팅 정보 수신을 동의합니다. (선택)</label>
                    </div>
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToEmailMarketing"
                            checked={formData.agreeToEmailMarketing}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToEmailMarketing" className="text-sm text-gray-500">이메일 마케팅 정보 수신을 동의합니다. (선택)</label>
                    </div>
                </div>

                <button
                    onClick={onClick}
                    className="w-full p-2 bg-slate-700 text-white hover:bg-slate-800 mt-10 2xl:mt-14"
                >
                    회원가입
                </button>
            </div>
        </div>
    );
}

export default SignUp;
