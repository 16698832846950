import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-regular-svg-icons";
import { faMagnifyingGlass, faLock } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";
import { timeAgo } from "../utils/timeAgo";
import { useMediaQuery } from "react-responsive";

const QnaList = () => {
    
    const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.auth.isLoggedIn,
    }));
    
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const location  = useLocation()
    const params = new URLSearchParams(location.search)
    const navigate = useNavigate()
    const { api } = useFetch()
    const [postData, setPostData] = useState([])
    const [noticeData, setNoticeData] = useState([])
    const [pageIndex, setPageIndex] = useState(1)
    const [pageCount, setPageCount] = useState(1)

    const setParams = params => {

        let result = ""

        if (params.has('page')) {
            result += `?page=${params.get('page')}`

            if (params.has('keyword')) {
                result += `&qna_type=${params.get('qna_type')}&keyword=${params.get('keyword')}`
            }
        }
        else if (params.has('keyword')) {
            result += `?qna_type=${params.get('qna_type')}&keyword=${params.get('keyword')}`
        }

        return result
    }

    useEffect(() => window.scrollTo({top: 0, left: 0, behavior: "smooth"}), [location.search])
    
    useEffect(() => {

        isLoggedIn ? 

        (async () => {
            try {
                const { data, error } = await api({
                    action: "getAuthQna",
                    query: setParams(params)
                })
                
                if (!error) {
                    setPostData(data.qna)
                    setPageCount(data.page_count)
                    if (params.has('page')) {
                        setPageIndex(params.get('page'))
                    }
                    console.log(params.get("keyword"), params.get('qna_type'))
                    console.log(data)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) () :
        
        (async () => {
            try {
                const { data, error } = await api({
                    action: "getQna",
                    query: setParams(params)
                })
                
                if (!error) {
                    setPostData(data.qna)
                    setPageCount(data.page_count)
                    if (params.has('page')) {
                        setPageIndex(params.get('page'))
                    }
                    console.log(params.get("keyword"), params.get('qna_type'))
                    console.log(data)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()

    }, [isLoggedIn, location.search])

    useEffect(() => {

        (async () => {
            try {
                const { data, error } = await api({
                    action: "getNotice",
                })

                if (!error) {
                    console.log(data)
                    setNoticeData(data.notice_list)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()
    }, [])

    const Notification = ({ data }) => {

        const { title, admin_nickname: writer, created_at: date, comment_count: commentCount } = data

        const fontGroup = "text-xs my-auto text-gray-500"

        return (
            <div className="flex flex-row justify-between h-10 bg-gray-100 border-b hover:bg-gray-200">
                <div className="flex flex-row justify-start " style={{fontSize: '0.9rem', lineHeight: '1.25rem'}}>
                    <div className="my-auto ml-5 font-bold">{title}</div>
                    <div className="text-sm my-auto ml-3 text-sky-800 font-bold">{commentCount}</div>
                </div>
                <div className="flex flex-row justify-start my-auto mr-3">
                    <div className={`${fontGroup}`}>{writer}</div>
                    <div className={`text-xs my-auto text-gray-400 mx-1`}>•</div>
                    <div className={`${fontGroup}`}>{timeAgo(date)}</div>
                    {/* <div className={`text-xs my-auto text-gray-400 mx-1`}>•</div>
                    <div className={`${fontGroup} mr-3 flex items-center`}>
                        <FontAwesomeIcon icon={faEye}/>
                        <span className="ml-[0.1rem]">{view}</span>
                    </div> */}
                </div>
            </div>
        )
    }

    const Post = ({ data }) => {
        
        const { title, writer, image_urls: image, view_count: view, created_at: createdAt, comment_count: commentCount, is_secret: isSecret } = data

        const fontGroup = "text-xs 2xl:text-sm my-auto text-gray-500"

        return (
            <div className={`flex flex-row justify-start w-full ${isMobile ? "h-20" : "h-24"} border-b pl-2 cursor-pointer hover:bg-slate-50`}>

                {image.length > 0 ? (
                    <div className={`flex ${isMobile ? "w-[60px] h-[60px]" : "w-[80px] h-[80px]"} m-2 rounded-sm overflow-hidden`}>
                        <img alt='qna_img' src={image[0]} className="w-full h-full object-cover"/>
                    </div>
                ) : null}
               
                <div className="flex flex-col justify-center w-full">
                    <div className="flex flex-row justify-start w-[65%] ml-2 ">
                        <div className="text-base mr-3 leading-[1.25rem] truncate">{title}</div>
                        <div className="text-sm text-sky-800 font-bold">{commentCount === 0 ? "" : commentCount}</div>
                    </div>
                    <div className="flex flex-row justify-start ml-2 mt-2 ">
                        <div className={`${fontGroup}`}>{writer}</div>
                        <div className={`text-xs text-gray-400 mx-1 my-auto`}>•</div>
                        <div className={`${fontGroup}`}>{timeAgo(createdAt)}</div>
                        <div className={`text-xs text-gray-400 mx-1 my-auto`}>•</div>
                        
                        {isSecret ?
                        <div className={`${fontGroup} flex items-center`}>
                            <FontAwesomeIcon icon={faLock}/>
                            {/* <span className="ml-[0.1rem]">{view}</span> */}
                        </div>
                        :
                        <div className={`${fontGroup} flex items-center`}>
                            <FontAwesomeIcon icon={faEye}/>
                            <span className="ml-[0.1rem]">{view}</span>
                        </div>
                        }
                    </div>
                </div>
            </div>
            
        )
    }
    
    const Paging = () => {
        const pageGroup = Math.floor((pageIndex - 1) / 5); // 현재 페이지 그룹
        const startPage = pageGroup * 5 + 1; // 그룹의 첫 번째 페이지
        const endPage = Math.min(startPage + 4, pageCount); // 그룹의 마지막 페이지
    
        return (
            <div className="flex flex-row justify-center w-full min-w-96 gap-3">
                
                {pageIndex > 5 ? (
                    <div onClick={() => {
                        const prevPageIndex = startPage - 1;
                        setPageIndex(prevPageIndex);
                        navigate({
                            pathname: '/service/qna',
                            search: params.has('keyword') ? `?qna_type=${params.get('type')}&keyword=${params.get('keyword')}&page=${prevPageIndex}` : `?page=${prevPageIndex}`
                        });
                    }}
                    className="cursor-pointer flex-shrink-0 mx-1 w-[7%] min-w-10 text-center hover:underline 2xl:text-lg">
                        이전
                    </div>
                ) : (<div className="flex-shrink-0 mx-1 w-[7%]"/>)}
    
                <div className="flex flex-shrink-0 justify-center mx-3 w-[35%]">
                    {Array.from({ length: endPage - startPage + 1 }, (_, index) => (
                        <div key={index} 
                            className={`${(startPage + index) === parseInt(pageIndex) ? 
                                "font-extrabold " : ""} px-2 mx-1 cursor-pointer flex-shrink-0 hover:underline 2xl:text-lg`}
                            id={`${startPage + index}`}
                            onClick={e => {
                                const nextPageIndex = parseInt(e.target.id);
                                setPageIndex(nextPageIndex);
                                navigate({
                                    pathname: `/service/qna`,
                                    search: params.has('keyword') ? `?qna_type=${params.get('type')}&keyword=${params.get('keyword')}&page=${nextPageIndex}` : `?page=${nextPageIndex}`
                                });
                            }}>
                            {startPage + index}
                        </div>
                    ))}
                </div>
    
                {endPage < pageCount ? (
                    <div onClick={() => {
                        const nextPageIndex = endPage + 1;
                        setPageIndex(nextPageIndex);
                        navigate({
                            pathname: '/service/qna',
                            search: params.has('keyword') ? `?qna_type=${params.get('type')}&keyword=${params.get('keyword')}&page=${nextPageIndex}` : `?page=${nextPageIndex}`
                        });
                    }}
                    className="cursor-pointer flex-shrink-0 mx-1 w-[7%] min-w-10 text-center hover:underline 2xl:text-lg">
                        다음
                    </div>
                ) : (<div className="flex-shrink-0 mx-1 w-[7%]"/>)}
    
            </div>
        )
    }

    const SearchBox = ({ params }) => {
        
        const [searchText, setSearchText] = useState(params.has('keyword') ? params.get('keyword') : "")
        const [searchType, setSearchType] = useState(params.has('qna_type') ? params.get('qna_type') : "title")

        return (
            <div className="flex flex-row justify-end w-full">
                <div className="flex flex-row justify-between w-[30%] min-w-64 max-w-96 h-9 mb-6 border-[1.5px] border-gray-700 bg-white">

                    <select className="bg-white focus:outline-none m-1 px-[0.15rem] text-sm text-center" 
                        onChange={e => {
                            switch(e.target.value) {
                                case '제목':
                                    setSearchType('title')
                                    break
                                case '내용':
                                    setSearchType('payload')
                                    break
                                case '작성자':
                                    setSearchType('writer')
                                    break
                                default:
                                    break
                            }
                        }}>
                        <option>제목</option>
                        <option>내용</option>
                        <option>작성자</option>
                    </select>

                    <input className=" w-[90%] px-1 m-1 mr-2 pl-3 bg-white focus:outline-none text-sm placeholder:italic"
                        value={searchText}
                        placeholder="검색어 입력" onChange={e => {setSearchText(e.target.value)}}
                        onKeyDown={e => {
                            if(e.key === 'Enter') 
                                navigate({
                                    pathname: '/service/qna',
                                    search: `?qna_type=${searchType}&keyword=${e.target.value}`
                                })
                            }
                        }
                    /> 
                    <FontAwesomeIcon icon={faMagnifyingGlass} size="lg" 
                        className="my-auto cursor-pointer text-gray-700 mr-2 transition-all duration-200 hover:scale-110"
                        onClick={() => {
                            navigate({
                                pathname: '/service/qna',
                                search: `?qna_type=${searchType}&keyword=${searchText}`
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className={`${isMobile ? "w-full pt-20 mb-20" : "w-4/6 pt-36 mb-56"} 2xl:w-[60%] min-h-screen flex flex-col justify-start 2xl:pt-48 mx-auto font-nanum `}>

            {!isMobile &&
            <div className="flex justify-start text-3xl font-bold font-raleway pb-5 ml-3 ">
                <div className="cursor-pointer " onClick={() => navigate('/service/qna')}>
                    Q&A
                </div>
            </div>
            }

            <div className={`${isMobile && "mr-2"}`}>
                <SearchBox params={params} />
            </div>
            

            <div className="font-nanum">
                {noticeData.map((data, index) => {
                    return (
                        <Link to={`/service/notice/${data.notice_id}`} key={index}>
                            <Notification data={data} />
                        </Link>
                    )
                })}
            </div>

            <div className="font-nanum">
                {postData.map((data, index) => {
                    return (
                        <Link to={`/service/contents/${data.id}`} key={index}>
                            <Post data={data} />
                        </Link>
                    )
                })}
            </div>

            <div className="flex flex-row xs:flex-col justify-between xs:justify-center mt-10">
                <div className="w-[20%] xs:hidden" />

                <div className="flex flex-row justify-center w-[70%] xs:w-full my-auto">
                    <Paging />
                </div>

                <div className="flex flex-row justify-end xs:w-full w-[20%] min-w-20 mr-3 xs:p-3">
                    <div className=" px-4 py-2 bg-slate-700 text-white rounded my-auto cursor-pointer"
                        onClick={() => isLoggedIn ? navigate('/service/write') : alert("로그인이 필요합니다.")}>
                        글쓰기
                    </div>
                </div>
                
            </div>

        </div>
    )
}

export default QnaList