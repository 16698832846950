import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useFetch } from "../hooks/useFetch"


const Products = () => {

    const { api } = useFetch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data, error } = await api({ action: "getItems" });

                if (error) {
                    console.error(error);
                } else {
                    setItems(data.item);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);


    const Item = ({ item }) => {
        const navigate = useNavigate();
        const { name, description, image_urls, price, id } = item;

        return (
            <div
                className="flex flex-col w-[48%] max-w-[48%] h-96 m-2 p-2 cursor-pointer"
                onClick={() => navigate(`/product/${id}`)}
            >
                <div className="h-[70%] overflow-hidden">
                    <img
                        className="w-full h-full object-cover "
                        src={image_urls[0]}
                        alt={name}
                    />
                </div>
                <div className="flex flex-col h-[30%] mt-2 overflow-hidden">
                    <span className="font-semibold text-lg">
                        {name}
                    </span>
                    <span className="text-gray-500">
                        ₩ {price.toLocaleString('ko-KR')}
                    </span>
                    {/* <span className="text-sm text-gray-700 mt-1">
                        {description}
                    </span> */}
                </div>
            </div>
        );
    };

    return (
        <div className="flex justify-center w-full min-h-screen">
            <div className="flex flex-wrap justify-between w-full max-w-screen-lg mx-auto">
                {items.map((item, index) => (
                    <Item key={index} item={item} />
                ))}
            </div>
        </div>
    );
};

export default Products;

