import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


export const TextEditor = ({ setText }) => {
  const modules = {
    toolbar: {
      container: [
        [{ header: [1, 2, false] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
        ['link'],
        [{ align: [] }, { color: [] }, { background: [] }],
        ['clean']
      ],
    },
  };

  return (
    <div className='w-full h-full'>
      <ReactQuill
        theme="snow"
        modules={modules}
        style={{ width: '100%', height: '95%' }}
        placeholder='Something write...'
        onChange={setText}
      />
    </div>
  );
};

