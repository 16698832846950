import Login from "../../pages/Login";


const Modal = ({ isOpen, closeModal }) => {

    return (
        isOpen ? (
            <div className="fixed inset-0 flex items-center justify-center z-50">
                <div className="absolute inset-0 bg-gray-900 opacity-50" onClick={closeModal} />
                <Login closeModal={closeModal} />
            </div>
        ) : (
            null
        )
    );
}

export default Modal