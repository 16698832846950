import { useState, useEffect } from 'react';
import { ReactComponent as TextLogo } from '../../asset/LOGO.svg';
import { useNavigate, useLocation } from 'react-router-dom';

const Navbar = () => {
    const [lastScrollY, setLastScrollY] = useState(0);
    const [navOffset, setNavOffset] = useState(0);
    const navigate = useNavigate();
    const location = useLocation()

    const SCROLL_THRESHOLD = 25; // 스크롤 민감도를 낮추기 위한 임계값
    const NAVBAR_TOTAL_HEIGHT = 100; // 네비게이션 바와 상단 메뉴바의 합산 높이 (60px + 40px)

    const handleScroll = () => {
        const currentScrollY = window.scrollY;
        const diff = currentScrollY - lastScrollY;

        if (Math.abs(diff) > SCROLL_THRESHOLD) { // 임계값을 넘어서는 경우에만 작동
            setNavOffset((prev) => {
                let newOffset = prev - diff;
                return Math.min(0, Math.max(newOffset, -NAVBAR_TOTAL_HEIGHT)); // 전체 네비게이션 바와 상단 메뉴바를 숨김
            });

            setLastScrollY(currentScrollY);
        }
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window.addEventListener('scroll', handleScroll);

            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }
    }, [lastScrollY]);

    return (
        <div 
            style={{ transform: `translateY(${navOffset}px)` }} 
            className={`${location.pathname === '/' ? "h-[101px]" : "h-[61px]"} w-full transition-transform duration-300 ease-out fixed top-0 z-20 border-b-[1.5px] border-gray-700 bg-white`}
        >
            <header className="flex justify-center items-center text-center w-full h-[60px]">
                <div className="flex items-center w-[20%] min-w-[120px]">
                    <TextLogo 
                        className="cursor-pointer" 
                        onClick={() => navigate('/')} 
                        style={{ width: '100%', height: 'auto' }} // SVG 크기 조정
                    />
                </div>
            </header>
            
            {/* 상단 메뉴바 */}
            {location.pathname === '/' && (
                <nav className="w-full border-t-[1.5px] border-black py-2 fixed top-[60px] left-0 bg-white z-10 h-[40px] mb-[40px]" aria-label="메인 내비게이션">
                    <div className="flex justify-around font-raleway">
                        <a href="/about" className="cursor-pointer" onClick={(e) => { e.preventDefault(); navigate('/about'); }}>About</a>
                        <a href="/collection" className="cursor-pointer" onClick={(e) => { e.preventDefault(); navigate('/collection'); }}>Collection</a>
                        <a href="/products" className="cursor-pointer" onClick={(e) => { e.preventDefault(); navigate('/products'); }}>Product</a>
                        <a href="/service/notice" className="cursor-pointer" onClick={(e) => { e.preventDefault(); navigate('/service/notice'); }}>Notice</a>
                        <a href="/contact" className="cursor-pointer" onClick={(e) => { e.preventDefault(); navigate('/contact'); }}>Contact</a>
                    </div>
                </nav>
            )}

            
        </div>
    );
};

export default Navbar;
