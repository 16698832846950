import { useMediaQuery } from "react-responsive";

const About = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
      
  return (

    <section className="p-8 w-full min-h-screen">
      <div className={`${isMobile ? "w-4/5 py-20" : "w-3/5 2xl:w-[50%] py-40"}  mx-auto`}>
        {/* 한글 설명 */}
        <h1 className="text-xl font-bold text-gray-800 text-center">FSO (Floral Stella Odyssey)</h1>
        <p className="mt-8 text-gray-800">
            FSO는 흥미로운 장편소설을 만들어가는 듯한 독특한 형식으로 탄생했으며 주인공들을 투영해 본인의 가치관을 찾아가는 브랜드입니다.
            독창성, 도전정신을 패션에 반영하여 각자의 고유성과 개성을 인정하고 포용하며 진취성을 고취시킵니다.
        </p>
        <p className="mt-4 text-gray-800">
            우주와 자연의 요소들에서 영감을 받아 본인만의 스타일로 창조해 호기심을 불러일으킵니다.
        </p>
        <blockquote className="mt-8 text-lg font-semibold text-gray-800">
            "끝없는 우주를 탐험하며 경험을 통해 내면을 발전시키며 본인만의 가치관을 만들어가자."
        </blockquote>
        <p className="mt-4 text-gray-800">
            라는 메시지를 전달하며, 우리와의 여정을 통해 당신만의 별을 찾기를 희망합니다.
        </p>

        {/* 영어 설명 */}
        <h2 className="mt-40 text-xl font-bold text-gray-800 text-center">FSO (Floral Stella Odyssey)</h2>
        <p className="mt-8 text-gray-600">
            FSO was created in a unique format that seemed to make interesting novels and is a brand that projects the main characters to find their values.
            By reflecting originality and the spirit of challenge in fashion, it recognizes and embraces each individual's uniqueness and individuality, and inspires initiative.
        </p>
        <p className="mt-4 text-gray-600">
            Inspired by elements of the universe and nature, they create their own style to arouse curiosity.
        </p>
        <blockquote className="mt-8 text-base font-semibold text-gray-800">
            "Let's explore the endless universe, develop ourselves through experiences, and create your own values."
        </blockquote>
        <p className="mt-4 text-gray-600">
            It sends the message, and we hope to find your own star through your journey with us.
        </p>
      </div>
    </section>
  );
}

export default About


