import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess } from '../store/reducers';
import { cookies } from '../utils/tokenStorage';
import { useAuth } from './useAuth';


const useOAuth = () => {

    const dispatch = useDispatch();
    const { fetchUserFromToken } = useAuth()

    const createOAuthProvider = () => {
        const url = 'https://www.fso.co.kr/';

        const providers = {
            kakao: {
                redirectUri: `${url}?provider=kakao`,
                apiKey: process.env.REACT_APP_KAKAO_API_KEY,
                nonce: process.env.REACT_APP_KAKAO_NONCE,
                getUrl() {
                    return `https://kauth.kakao.com/oauth/authorize?client_id=${encodeURIComponent(this.apiKey)}&response_type=code&redirect_uri=${encodeURIComponent(this.redirectUri)}&nonce=${encodeURIComponent(this.nonce)}`;
                },
            },
            naver: {
                redirectUri: `${url}?provider=naver`,
                apiKey: process.env.REACT_APP_NAVER_API_KEY,
                stateString: process.env.REACT_APP_NAVER_STATE_STRING,
                getUrl() {
                    return `https://nid.naver.com/oauth2.0/authorize?client_id=${encodeURIComponent(this.apiKey)}&state=${encodeURIComponent(this.stateString)}&response_type=code&redirect_uri=${encodeURIComponent(this.redirectUri)}`;
                },
            },
        };

        return (provider) => {
            const config = providers[provider];
            return config ? config.getUrl() : null;
        };
    };

    const getOAuthUrl = useMemo(createOAuthProvider, []);

    const oAuthUrl = (provider) => {
        return getOAuthUrl(provider);
    };

    const oAuthLogin = ({ accessToken, secretToken }) => {

        cookies.set('access', accessToken);
        cookies.set('secret', secretToken);

        fetchUserFromToken()
    };

    return { oAuthUrl, oAuthLogin,};
};

export default useOAuth;
