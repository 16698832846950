import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../../hooks/useFetch';


export const Success = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const { api } = useFetch()

    const paymentType = searchParams.get('paymentType');
    const orderId = searchParams.get('orderId');
    const paymentKey = searchParams.get('paymentKey');
    const amount = searchParams.get('amount');

    const queryString = `?paymentType=${paymentType}&orderId=${orderId}&paymentKey=${paymentKey}&amount=${amount}`;

    console.log(queryString)

    useEffect(() => {
        if (!paymentKey || !orderId || !amount) {
            console.error('필수 결제 정보가 없습니다.');
            return;
        }

        (async () => {
            try {
                const { data, error } = await api(
                    { 
                        action: "paymentSuccess",
                        query: queryString
                    }
                );
                if (error) {
                    console.error(error);
                } else {
                    console.log(data)
                }
            } catch (err) {
                console.error(err);
            }
        }) ()

    }, [location.search])

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <h1 className="text-3xl font-bold font-nanum">결제가 성공적으로 완료되었습니다!</h1>
            </div>
        </div>
    );
};


export const Fail = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const orderId = searchParams.get('orderId');
    const errorCode = searchParams.get('code');
    const errorMessage = searchParams.get('message');

    return (
        <div className="flex justify-center items-center h-screen">
            <div className="text-center">
                <h1 className="text-3xl font-bold font-nanum">결제가 실패하였습니다.</h1>
                <p className="mt-4 text-lg">주문 ID: {orderId}</p>
                <p className="mt-2 text-lg">오류 코드: {errorCode}</p>
                <p className="mt-2 text-lg">오류 메시지: {errorMessage}</p>
            </div>
        </div>
    );
};