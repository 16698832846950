import { useState, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import { TextEditor } from "../components/common/TextEditor";
import { useFetch } from "../hooks/useFetch";
import { useNavigate } from "react-router-dom";

const QnaCreate = () => {
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const [isSecret, setIsSecret] = useState(false);
    const [title, setTitle] = useState("");
    const [isCustomTitle, setIsCustomTitle] = useState(true);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [fileNames, setFileNames] = useState([]);
    const [text, setText] = useState("")

    const { api } = useFetch()
    const navigate = useNavigate()

    useEffect(() => window.scrollTo({top: 0, behavior: "auto"}), [])

    const handleFileChange = (event) => {
        const filesArray = [...event.target.files];

        filesArray.forEach(file => {

            if (!selectedFiles.some(existingFile => existingFile.name === file.name)) {
                if (file.type.startsWith('image/')) {
                    const reader = new FileReader();
                    reader.onload = (e) => {
                        file.preview = e.target.result;
                        setSelectedFiles(prevFiles => [...prevFiles, file]); // state 갱신
                    };
                    reader.readAsDataURL(file);
                } else {
                    setSelectedFiles(prevFiles => [...prevFiles, file]); // state 갱신
                }
                setFileNames(prevNames => [...prevNames, file.name]);
            }
        });

        event.target.value = null;

    };

    const handleRemoveFile = (index) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(index, 1);
        setSelectedFiles(newFiles);

        const newNames = [...fileNames];
        newNames.splice(index, 1);
        setFileNames(newNames);
    };

    const inquireType = [
        {
            title: 'etc',
            text: "직접 입력"
        },
        {
            title: "product",
            text: "상품 관련 문의",
        },
        {
            title: "size",
            text: "사이즈 관련 문의",
        },
        {
            title: "exchange",
            text: "교환/반품 관련 문의",
        },
        {
            title: "shipping",
            text: "배송 관련 문의",
        },
        {
            title: "refund",
            text: "환불 관련 문의",
        },
        
    ];

    const postContents = async () => {

        if (!title.trim()) {
            alert("제목을 입력해주세요.");
            return;
        }
    
        if (!text.trim()) {
            alert("내용을 입력해주세요.");
            return;
        }

        const formData = new FormData()

        formData.append('qna_creation_request', JSON.stringify({
            'title': title,
            'payload': text,
            'is_secret': isSecret
        }))
        
        if (selectedFiles.length > 0) {
            selectedFiles.forEach((file) => {
                formData.append('qna_creation_images', file);
            });
        }

        console.log(formData)

        const { response, error } = await api({
            action: "setQna",
            params: formData
        })
        if (!error) {
            console.log(response)
            alert("등록되었습니다.")
            navigate('/service')
        } else {
            console.error(error)
        }
    };

    return (
        <div className="flex flex-row justify-center h-[110vh] 2xl:h-[100lvh] w-full 2xl:mb-[15lvh] ">

            <div className={`flex flex-col ${isMobile ? "w-full mx-4" : "w-4/5"} xl:w-[70%] 2xl:w-[65%] h-full justify-start pt-36 items-center`}>

                <div className="flex flex-row justify-start w-full h-[5%] 2xl:h-[3%] mx-auto mb-10 px-1 text-2xl 2xl:text-3xl font-bold" >
                    Inquire
                </div>

                <div className="flex flex-row justify-between w-full mx-auto mb-5 ">
                    <input
                        className="w-[69%] max-h-14 p-2 border border-gray-500 px-3 placeholder:italic items-center "
                        type="text"
                        placeholder="상품 관련 문의"
                        value={title}
                        onChange={(e) => isCustomTitle && setTitle(e.target.value)}
                        readOnly={!isCustomTitle}
                    />

                    <select
                        onChange={(e) => {
                            if (e.target.value !== "직접 입력") {
                                setTitle(e.target.value);
                                setIsCustomTitle(false);
                            } else {
                                setTitle("");
                                setIsCustomTitle(true);
                            }
                        }}
                        className="w-[29%] min-w-28 max-h-14 p-2 border border-gray-500 px-3 items-center"
                    >
                        {inquireType.map((elem, index) => (
                            <option key={index} value={elem.text}>{elem.text}</option>
                        ))}
                    </select>
                </div>

                <div className="flex w-full h-[60%] 2xl:h-[75%] mx-auto">
                    <TextEditor text={text} setText={setText} />
                </div>

                <div className="flex flex-row justify-between w-full h-[5%] mx-auto mt-10 py-5 ">

                    <div className="flex flex-row items-center w-2/3">
                        <label htmlFor="fileInput" className="bg-slate-700 text-white px-4 py-2 mx-2 rounded-md shadow-md cursor-pointer min-w-24">
                            파일 첨부
                            <input id="fileInput" type="file" onChange={handleFileChange} multiple className="hidden" />
                        </label>

                        <div className="flex flex-row justify-start w-[70%] h-32 items-center overflow-hidden">
                            {selectedFiles.length > 0 &&
                            selectedFiles.map((file, index) => (
                                <div key={index} className="w-[20%] min-w-16 max-w-20 h-[70%] bg-gray-200 ml-5 overflow-hidden cursor-pointer hover:scale-90 transition-all duration-300"
                                    onClick={() => handleRemoveFile(index)}>
                                    {file.preview && <img src={file.preview} alt="Preview" className="w-full h-full object-cover "/>}
                                    {/* <div className="absolute top-0 right-0 m-1 px-1 bg-red-500 text-white text-xs rounded-full hover:bg-red-700 cursor-pointer" */}
                                    <div className="absolute top-0 right-0 px-1 m-1 text-xs text-red-700 2xl:test-sm rounded-full cursor-pointer"
                                        onClick={(e) => {
                                            e.stopPropagation(); 
                                            handleRemoveFile(index);
                                        }}>
                                        x
                                    </div>
                                </div>
                            ))}         
                        </div>
                    </div>
                    
                    <div className="flex flex-row w-[30%] justify-end ">
                        <div className="flex flex-row items-center cursor-pointer mr-4 " onClick={()=> setIsSecret(!isSecret)}>
                            {isMobile ? 
                            <div className="mr-3 truncate">비공개</div> : 
                            <div className="mr-3 2xl:text-base truncate">비밀글로 등록하기</div>}
                            
                            <input
                                className="h-4 w-4"
                                type="checkbox"
                                checked={isSecret}
                                onChange={() => setIsSecret(!isSecret)}
                            />
                        </div>
                        
                        <div className="flex flex-row items-center mr-2">
                            <button className="min-w-16 mr-3 px-4 py-2 bg-gray-300 rounded"
                                onClick={() => console.log(text)}>
                                취소
                            </button>
                            <button className="min-w-16 px-4 py-2 bg-slate-700 text-white rounded"
                                onClick={postContents}>
                                등록
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QnaCreate;
