import { faQuestion, faHouse, faBasketShopping, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../../hooks/useAuth';

const BottomNavbar = () => {
    
    const navigate = useNavigate();
    const { logout } = useAuth()
    const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.auth.isLoggedIn,
    }));

    return (
        <>
            {/* 하단 네비게이션 바 */}
            <nav 
                className="fixed bottom-0 left-0 w-full h-[60px] font-raleway bg-white border-t-[1.5px] border-gray-700 z-50"
                style={{ position: 'fixed', bottom: 0, left: 0 }}
            >
                <div className="flex justify-around items-center h-full">
                    <div className="flex flex-col w-[20%] items-center" onClick={() => navigate('/service/qna')}>
                        {/* <FontAwesomeIcon icon={faQuestion} className="h-4 w-4" /> */}
                        <span className="text-base font-semibold">F&Q</span>
                    </div>
                    <div className="flex flex-col w-[20%] items-center" onClick={() => navigate('/')}>
                        {/* <FontAwesomeIcon icon={faHouse} className="h-4 w-4" /> */}
                        <span className="text-base font-semibold">Home</span>
                    </div>
                    

                    {isLoggedIn ? (
                    <>

                    <div className="flex flex-col w-[20%] items-center" onClick={() => navigate('/cart')}>
                        {/* <FontAwesomeIcon icon={faBasketShopping} className="h-4 w-4" /> */}
                        <span className="text-base font-semibold">Cart</span>
                    </div>


                    <div className="flex flex-col w-[20%] items-center" onClick={() => navigate('/mypage')}>
                        {/* <FontAwesomeIcon icon={faUser} className='h-4 w-4' /> */}
                        <span className="text-base font-semibold">Mypage</span>
                    </div> 

                    <div className="flex flex-col w-[20%] items-center"
                        onClick={() => {
                            logout()
                            alert('로그아웃 되었습니다.')
                        }}>
                        {/* <FontAwesomeIcon icon={faUser} className='h-4 w-4' /> */}
                        <span className="text-base font-semibold">Logout</span>
                    </div> 
                    </>
                    ) : ( 
                    <div className="flex flex-col w-[20%] items-center" onClick={() => navigate('/login')}>
                        {/* <FontAwesomeIcon icon={faUser} className='h-4 w-4' /> */}
                        <span className="text-base font-semibold">Login</span>
                    </div>
                    )}
                </div>
            </nav>

        </>
    );
};

export default BottomNavbar;
