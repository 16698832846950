import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useFetch } from "../hooks/useFetch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleDown, faAngleDoubleUp } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

const MyPage = () => {

    const navigate = useNavigate()

    const { userData } = useSelector((state) => ({
        userData: state.auth.userData,
    }));

    if (!userData) {
        return (
            <div className="min-h-screen max-w-2xl mx-auto p-6 mt-10 mb-20">
                <p className="text-center text-gray-600">회원 정보가 없습니다. 로그인 후 이용해주세요.</p>
            </div>
        );
    }

    const { id, name, nickname } = userData;

    const OrderStatus = () => {
        const { api } = useFetch();
        const [selectedTab, setSelectedTab] = useState("주문완료");
        const [orderData, setOrderData] = useState([]);
        const [isOpen, setIsOpen] = useState(true);

        useEffect(() => {
            const fetchOrderData = async () => {
                // 샘플 데이터 추가
                const sampleData = [
                    {
                        orderNumber: "0123456788-0123456789",
                        order_status: "ORDER_PLACED",
                        ordering_date: "2023-10-15",
                        items: [
                            { item_name: "Gypsophilia T-shirt", quantity: 1, price: 39000 },
                            { item_name: "Rose Cap", quantity: 2, price: 15000 }
                        ]
                    },
                    {
                        orderNumber: "0123456788-0123456789",
                        order_status: "ORDER_PLACED",
                        ordering_date: "2023-10-15",
                        items: [
                            { item_name: "Gypsophilia T-shirt", quantity: 1, price: 39000 },
                            { item_name: "Rose Cap", quantity: 2, price: 15000 }
                        ]
                    },
                    {
                        orderNumber: "0123456788-1234567890",
                        order_status: "DELIVERED",
                        ordering_date: "2023-10-16",
                        items: [
                            { item_name: "Rose Hoodie", quantity: 1, price: 49000 }
                        ]
                    },
                    {
                        orderNumber: "0123456788-0987654321",
                        order_status: "RETURNED",
                        ordering_date: "2023-10-17",
                        items: [
                            { item_name: "Sunflower Hat", quantity: 1, price: 20000 },
                            { item_name: "Daisy Bag", quantity: 1, price: 30000 }
                        ]
                    }
                ];

                setOrderData(sampleData); // 샘플 데이터 설정
            };

            fetchOrderData();
        }, []);

        // 주문 상태별 필터링
        const filteredOrders = orderData.filter((order) => {
            switch (selectedTab) {
                case "주문완료":
                    return order.order_status === "ORDER_PLACED";
                case "주문확정":
                    return order.order_status === "DELIVERED";
                case "환불완료":
                    return order.order_status === "RETURNED";
                case "교환완료":
                    return order.order_status === "ORDER_CANCELLED";
                default:
                    return false;
            }
        });

        return (
            <div className="border-b border-gray-400 mb-8">
                <div className="font-bold mb-12 cursor-pointer text-xl font-raleway flex justify-between items-center" onClick={() => setIsOpen(!isOpen)}>
                    ORDER STATUS
                    <FontAwesomeIcon icon={isOpen ? faAngleDoubleUp : faAngleDoubleDown} className="ml-2 cursor-pointer" />
                </div>

                {isOpen && (
                    <div className="mb-12 font-nanum">
                        {/* 주문 상태 탭 메뉴 */}
                        <div className="flex justify-around mb-8 text-gray-600 ">
                            {["주문완료", "주문확정", "환불완료", "교환완료"].map((status) => (
                                <button
                                    key={status}
                                    className={`px-4 py-2 ${selectedTab === status ? "border-b-2 border-[#2A2F5A] text-[#2A2F5A] font-semibold" : "text-gray-500"}`}
                                    onClick={() => setSelectedTab(status)}
                                >
                                    {status}
                                </button>
                            ))}
                        </div>

                        {/* 주문 정보 카드 */}
                        {filteredOrders.length > 0 ? (
                            filteredOrders.map((order, index) => (
                                <div key={index} className="bg-gray-50 border border-gray-200 p-4 rounded mb-4">
                                    <div className="flex justify-between mb-2">
                                        <span className="font-medium text-gray-700">주문번호</span>
                                        <span className="text-sm text-gray-900">{order.orderNumber}</span>
                                    </div>
                                    <div className="flex justify-between mb-4">
                                        <span className="font-medium text-gray-700">주문일자</span>
                                        <span className="text-sm text-gray-900">{new Date(order.ordering_date).toLocaleDateString("ko-KR")}</span>
                                    </div>

                                    {/* 여러 항목 렌더링 */}
                                    <div className="mt-2">
                                        {order.items.map((item, itemIndex) => (
                                            <div key={itemIndex} className="flex justify-between items-center p-3 mb-1 bg-white border border-gray-200 rounded">
                                                <div className="flex items-center">
                                                    <span className="font-medium text-gray-900 mr-2">{item.item_name}</span>
                                                    <span className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded-full">{item.quantity}개</span>
                                                </div>
                                                <span className="text-sm text-gray-900">{(item.price * item.quantity).toLocaleString("ko-KR")} 원</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="min-h-32 flex items-center justify-center text-center text-gray-600 mb-4">
                                주문 내역이 없습니다.
                            </p>
                        )}
                    </div>
                )}
            </div>
        );
    };

    const CouponStatus = () => {
        const { api } = useFetch();
        const [ownedCoupons, setOwnedCoupons] = useState([]);
        const [availableCoupons, setAvailableCoupons] = useState([]);
        const [isOpen, setIsOpen] = useState(true);

        useEffect(() => {
            const fetchCoupons = async () => {
                try {
                    const [ownedCouponsResponse, availableCouponsResponse] = await Promise.all([
                        api({ action: "getUserCoupon" }),
                        api({ action: "getCoupon" })
                    ]);

                    if (ownedCouponsResponse && availableCouponsResponse) {
                        if (!ownedCouponsResponse.error) {
                            setOwnedCoupons(ownedCouponsResponse.data);
                        } else {
                            console.error(ownedCouponsResponse.error);
                        }

                        if (!availableCouponsResponse.error) {
                            setAvailableCoupons(availableCouponsResponse.data);
                        } else {
                            console.error(availableCouponsResponse.error);
                        }
                    } else {
                        console.error("Invalid response from API");
                    }
                } catch (err) {
                    console.error("Error fetching coupons", err);
                }
            };

            fetchCoupons();
        }, []);

        const formatDate = (dateString) => {
            const options = { year: 'numeric', month: 'short', day: 'numeric' };
            return new Date(dateString).toLocaleDateString('ko-KR', options);
        };

        const getDiscountInfo = (coupon) => {
            if (coupon.type === 'PRICE') {
                return `${coupon.discount_price}원 할인`;
            }
            if (coupon.type === 'RATIO') {
                return `${coupon.discount_ratio}% 할인`;
            }
            return '';
        };

        return (
            <div className="border-b border-gray-400 mb-4">
                <div className="font-bold mb-12 cursor-pointer text-xl font-raleway flex justify-between items-center" onClick={() => setIsOpen(!isOpen)}>
                    COUPON
                    <FontAwesomeIcon icon={isOpen ? faAngleDoubleUp : faAngleDoubleDown} className="ml-2 cursor-pointer" />
                </div>

                {isOpen && (
                    <div>
                        <div className="mb-12 font-nanum">
                            <h3 className="text-lg font-semibold mb-4">보유 쿠폰 ({ownedCoupons.length}장)</h3>
                            <div className="space-y-4">
                                {ownedCoupons.length > 0 ? (
                                    ownedCoupons.map((coupon) => (
                                        <div key={coupon.coupon_id} className="border border-gray-300 rounded-lg p-5">
                                            <div className="text-base font-semibold">{coupon.name}</div>
                                            <div className="text-sm text-gray-500">{getDiscountInfo(coupon)}</div>
                                            <div className="text-sm text-gray-400">
                                                {formatDate(coupon.started_at)} ~ {formatDate(coupon.finished_at)}
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <p className="min-h-32 flex items-center justify-center text-center text-gray-600 mb-4">
                                        보유한 쿠폰이 없습니다.
                                    </p>
                                )}
                            </div>
                        </div>

                        <div className="mb-8 font-nanum">
                            <h3 className="text-lg font-semibold mb-4">수령 가능한 쿠폰 ({availableCoupons.length}장)</h3>
                            <div className="space-y-4">
                                {availableCoupons.length > 0 ? (
                                    availableCoupons.map((coupon) => (
                                        <div key={coupon.coupon_id} className="border border-gray-300 rounded-lg p-5 flex justify-between items-center">
                                            <div>
                                                <div className="text-base font-semibold">{coupon.name}</div>
                                                <div className="text-sm text-gray-500">{getDiscountInfo(coupon)}</div>
                                                <div className="text-sm text-gray-400">
                                                    {formatDate(coupon.started_at)} ~ {formatDate(coupon.finished_at)}
                                                </div>
                                            </div>
                                            <button 
                                                className="bg-slate-700 text-white py-2 px-3 rounded"
                                                onClick={async () => {
                                                    await api({
                                                        action: "setUserCoupon",
                                                        params: {
                                                            couponId: coupon.coupon_id
                                                        }
                                                    });
                                                    window.location.reload();
                                                }}
                                            >
                                                쿠폰 받기
                                            </button>
                                        </div>
                                    ))
                                ) : (
                                    <p className="min-h-32 flex items-center justify-center text-center text-gray-600 mb-4">
                                        수령 가능한 쿠폰이 없습니다.
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    return (
        <div className="min-h-screen max-w-2xl mx-auto p-6 mt-10 mb-20">
            <div className="border-b border-gray-400 pb-8 mb-8 flex flex-col">
                <h2 className="font-bold font-raleway text-2xl mb-10">MY PAGE</h2>
                <div className="flex justify-between items-start font-nanum">
                    <div>
                        <p className="text-lg font-semibold mb-1">{name}</p>
                        <p className="text-sm text-gray-600 mb-1">{nickname}</p>
                        {/* <p className="text-sm text-gray-600 mb-4">{id}</p> */}
                    </div>

                    <div className="self-end mt-4">
                        <button className="bg-slate-700 text-white py-2 px-5 rounded" onClick={() => {navigate('/edit')}}>수정</button>
                    </div>
                </div>
            </div>

            <OrderStatus />
            <CouponStatus />
        </div>
    );
};

export default MyPage;
