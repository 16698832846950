import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useFetch } from "../hooks/useFetch"


const Products = () => {
    const { api } = useFetch();
    const [items, setItems] = useState([]);

    useEffect(() => {
        (async () => {
            try {
                const { data, error } = await api({ action: "getItems" });

                if (error) {
                    console.error(error);
                } else {
                    setItems(data.item);
                }
            } catch (err) {
                console.error(err);
            }
        })();
    }, []);

    const Item = ({ item }) => {
        const navigate = useNavigate();
        const { name, image_urls, price, id } = item;

        return (
            <div 
                className="flex flex-col justify-center w-[30%] max-w-[30%] min-h-[450px] p-2 cursor-pointer font-nanum" 
                onClick={() => navigate(`/product/${id}`)}>
                <div className="flex flex-col w-full h-[70%]">
                    <div className="relative w-full h-full overflow-hidden">
                        <img 
                            className="w-full h-full object-cover rounded transition-opacity duration-1000 inset-0 z-5 hover:opacity-0 border border-zinc-200" 
                            src={image_urls[0]} 
                            alt={name} 
                        />
                        <img 
                            className="w-full h-full object-cover rounded transition-opacity duration-1000 inset-0 opacity-0 z-1 hover:opacity-100 border border-zinc-200" 
                            src={image_urls[1]} 
                            alt={name} 
                        />
                    </div>
                </div>
                <div className="flex flex-col justify-start w-full h-[30%] text-left mx-auto mt-6">
                    <span className="text-lg pb-1 font-extrabold hover:underline mx-auto">{name}</span>
                    <span className="text-base mx-auto font-bold">₩ {price.toLocaleString('ko-KR')}</span>
                </div>
            </div>
        );
    };

    return (
        <div className="flex flex-col w-4/5 min-h-[150lvh] mx-auto pt-[10lvh] animate-componentFadeIn">
            <h1 className="text-3xl font-bold pb-12 ml-2">Product</h1>
            <div className="flex flex-wrap justify-between w-full h-auto mx-auto">
                {items.map((item, index) => (
                    <Item key={index} item={item} />
                ))}
            </div>
        </div>
    );
};

export default Products;

