import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { useAuth } from "../../hooks/useAuth"
import BottomNavbar from "./BottomNavbar";
import Navbar from "./Navbar";
import Footer from "./Footer";

const Layout = () => {

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const { fetchUserFromToken } = useAuth()
    
    useEffect(()=> {
        if (!isLoggedIn) {
            (async () => await fetchUserFromToken()) ();
        }
    }, [])

    return (
        <div>
            <Navbar />
            <div className="w-full pt-[60px] pb-[20px]">
                <Outlet />
                <Footer />
            </div>
            <BottomNavbar />
        </div>
    )
}

export default Layout