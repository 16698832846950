import { useParams, useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect, useState, useRef } from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEye } from "@fortawesome/free-regular-svg-icons"
import { faEllipsisVertical, faPen, faTrash } from "@fortawesome/free-solid-svg-icons"
import { useFetch } from "../hooks/useFetch"
import { timeAgo } from "../utils/timeAgo"
import { useMediaQuery } from "react-responsive";

import DOMPurify from 'dompurify';

const QnaDetail = () => {

    const { userData, isLoggedIn } = useSelector(state => ({
        userData: state.auth.userData,
        isLoggedIn: state.auth.isLoggedIn,
    }));
    
    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
    const location = useLocation();
    const isNotice = location.pathname.includes('/notice');
    const { id } = useParams();
    const { api } = useFetch()

    const [postData, setPostData] = useState({})
    const [commentData, setCommentData] = useState({})
    const [commentText, setCommnetText] = useState("")

    useEffect(() => window.scroll(0, 0), [])

    useEffect(() => {
        
        isNotice ?
        
        (async () => {
            try {
                const { data, error } = await api({
                    action: "getNotice",
                    query: `/${id}`
                })
                
                if (!error) {
                    setPostData(data)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) () :
        
        (async () => {
            try {
                const { data, error } = await api({
                    action: "getQna",
                    query: `/${id}`
                })
                
                if (!error) {
                    setPostData(data)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()
    }, [])

    useEffect(() => {
        
        !isNotice &&
        (async () => {
            try {
                const { data, error } = await api({
                    action: "getQnaComment",
                    query: `/${id}`
                })
                
                if (!error) {
                    console.log(data)
                    setCommentData(data)
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()
    }, [])

    const fontStyle = " my-auto text-gray-500"


    const Comment = ({ commentData, userData }) => {

        const { writer: name, payload: mainText, comment_id: commentId, created_at: createdAt } = commentData
        const [showMenu, setShowMenu] = useState(false);
        const menuRef = useRef(null);

        const toggleMenu = () => {
            setShowMenu(!showMenu);
        };

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setShowMenu(false);
            }
        };
    
        useEffect(() => {
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, []);

        return (
            <div className="flex flex-row justify-between w-full py-4 border-b hover:bg-slate-50 rounded">
                <div className="flex flex-row ml-1">
                    <div className="flex flex-col ml-3 ">
                        <div className="flex flex-row mb-3">
                            <div className="font-bold text-sm ">
                                {name}
                            </div>
                            <div className="text-gray-400 text-sm ml-2">
                                {timeAgo(createdAt)}
                            </div>
                        </div>

                        <div className="flex flex-row my-auto">
                            <div>
                                {mainText}
                            </div>
                        </div>
                    </div>
                </div>
                
                {userData?.name === name && 
                <div className="relative mr-5" ref={menuRef}>
                    <FontAwesomeIcon icon={faEllipsisVertical} className="cursor-pointer" onClick={toggleMenu}/>
                    {showMenu && (
                    <div className="absolute right-full top-0 mt-2 bg-white border border-gray-200 rounded shadow-md transform translate-x-[-10px]">
                        <div className="py-2">
                            
                            <button className="w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 whitespace-nowrap"
                                onClick={async () => {
                                    // await api({
                                    //     action: "updateQnaComment",
                                    //     params: {
                                            
                                    //     }
                                    // })
                                }}
                            >
                                <FontAwesomeIcon icon={faPen} className="mr-1"/>수정
                            </button>

                            <button className="w-full text-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 whitespace-nowrap"
                                onClick={async () => {
                                    await api({
                                        action: "removeQnaComment",
                                        query: `/${commentId}`
                                    })
                                    window.location.reload();
                                }}
                            >
                                <FontAwesomeIcon icon={faTrash} className="mr-1"/>삭제
                            </button>
                        </div>
                    </div>
                    )}
                </div>}
                
            </div>      
        )
    }

    return (
        <div className={`${isMobile ? "w-full pt-[5lvh] px-3" : "w-[60%] pt-[20lvh] mb-56"} flex flex-col justify-center 2xl:w-[55%] 2xl:pt-48 mx-auto font-nanum`}>

            <div className="text-xl font-bold pb-1 mx-2 break-words">{postData.title}</div>

            <div className="flex flex-row justify-between ">
                <div className="flex flex-row text-sm mt-1 ml-2 ">
                    <div className={`${fontStyle}`}>{isNotice ? postData.admin_nickname : postData.writer}</div>
                    <div className={`text-xs text-gray-400 mx-1 my-auto`}>•</div>
                    <div className={`${fontStyle}`}>{timeAgo(postData.created_at)}</div>
                    {!isNotice && 
                    <>
                        <div className={`text-xs text-gray-400 mx-1 my-auto`}>•</div>
                        <div className={`${fontStyle}`}>
                            <FontAwesomeIcon icon={faEye}/>
                            <span className="ml-[0.1rem]">{postData.view_count}</span>
                        </div>
                    </>
                    }
                </div>
                
                {userData?.name === postData?.writer &&
                    <FontAwesomeIcon icon={faEllipsisVertical} className="cursor-pointer mr-2 text-xl" />
                }
                
            </div>

            <hr className=" h-[0.1rem] bg-slate-200 my-4"/>

            <div className="inline ml-1 min-h-[40lvh]">
                <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(postData.payload) }} />
            </div>

            <hr className=" h-[0.1rem] bg-slate-200 mt-24"/>
            
            {!isNotice && 
            <div className="flex flex-col w-full py-3">

                {!isMobile &&
                <div className="font-bold text-xl my-5">
                    Comments
                </div>
                }
                

                {commentData.length > 0 && commentData.map((elem, index)=> {
                    return( 
                        <Comment
                            key={index}
                            commentData={elem}
                            userData={userData}
                        />
                    )
                })}

                <div className="flex flex-col px-3 w-full pb-[20lvh]">
                    
                    <textarea className="flex w-full h-32 p-3 resize-none border-2 mt-5 border-slate-300 focus:border-gray-300 focus:outline-none"
                        placeholder={isLoggedIn ? '댓글을 작성해주세요' : '로그인이 필요합니다'}
                        value={commentText}
                        onChange={e => setCommnetText(e.target.value)}
                    />

                    <div className="flex flex-row justify-end">
                        <button
                            className="w-20 flex justify-center px-6 py-3 mt-3 bg-slate-700 text-white rounded"
                            onClick={async () => {
                                if (!isLoggedIn) {
                                    alert("로그인이 필요합니다.");
                                    return
                                }

                                if (!commentText.trim()) {
                                    alert("댓글을 입력해주세요.");
                                    return
                                }

                                try {
                                    await api({
                                        action: "setQnaComment",
                                        params: {
                                            payload: commentText,
                                            baseQna: id,
                                        },
                                    });
                                    setCommnetText("");
                                    window.location.reload();
                                } catch (error) {
                                    console.error("댓글 등록 중 오류가 발생했습니다", error);
                                }
                            }}
                        >
                            등록
                        </button>
                    </div>

                </div>
            </div>
            }
            

        </div>
    )
}

export default QnaDetail