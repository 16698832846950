import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useSelector } from "react-redux"
import { useFetch } from "../hooks/useFetch"
import { Carousel } from "../components/common/Carousel"
import { DetailInfo } from "../components/common/DetailInfo"
import { SelectArea } from "../components/common/SelectArea"

const Product = () => {

	const { api } = useFetch()
	const location = useLocation()
	const pathSegments = location.pathname.split('/');
	const productId = pathSegments[pathSegments.length - 1];
	const [item, setItem] = useState()
	const [isLoading, setIsLoading] = useState(true)

	const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.auth.isLoggedIn,
    }));

	useEffect(() => window.scroll(0, 0), [])

	useEffect(() => {
		(async () => {
			try {
				const { data, error } = await api({
					action: "getItem",
					query: `${productId}`
				});

				if (error) {
					console.error(error);
				} else {
					setItem(data)
				}
			} catch (err) {
				console.error(err);
			} finally {
				setIsLoading(false)
			}
		})();
	}, [productId])

	return (
		<div>
			{isLoading ? (
			<div>
				Loading...
			</div>
			): (
			<>
			<div className="w-full">
				<div className="w-full font-nanum">
					<Carousel images={item.image_urls} isMobile={true} />
                    
                    <div className="m-5">
                        <DetailInfo item={item} />
                        <SelectArea item={item} isLoggedIn={isLoggedIn}/>
                    </div>
				</div>
			</div>
			</>
			)}

            <div className="flex-col w-full mt-24 font-nanum">
				<hr className=" bg-black border m-5"/>
				<div className=" flex flex-col justify-center w-[70%] overflow-hidden mx-auto pt-5">
					{item?.image_urls.map((elem, key) => {
						return (
							<img className="my-5" src={elem} alt="product" key={key}/>
						)
					})}

					<div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
						{item?.description}
					</div>
				</div>
			</div>

		</div>
	)
}

export default Product
