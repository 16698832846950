import { useEffect, useState } from "react"
import { useMediaQuery } from 'react-responsive';


export const DetailInfo = ({ item }) => {

	const [ select, setSelect ] = useState("detail")

	// detail 창 여닫음을 className으로 처리해서 생긴 똥
	const isLargeScreen = useMediaQuery({ query: '(min-width: 1536px)' });

	useEffect(() => {
		if (isLargeScreen) {
			console.log("large")
			setDetailStyle_({
				fontSize: '1rem',
				lineHeight: '1.5rem',
			});
		}
	}, [isLargeScreen]);


	const { name, price, item_details_menu: detailInfo } = item
	const { details = null, fit_sizing = null, fabric = null} = detailInfo || {}

	const [detailStyle_, setDetailStyle_] = useState({
		fontWeight:'bold',
		fontSize: '0.875rem',
		lineHeight: '1.3rem',
		cursor: 'text',
	})

	const detailStyle = "cursor-pointer overflow-hidden duration-100 text-sm 2xl:text-lg leading-7 2xl:leading-9"

	const onClick = e => {
		const target = e.target.id
		const targetDiv = document.getElementById(`${target}_`)

		if (target === select) {
			if (targetDiv.classList.contains("animate-productDetailIn")) {
				document.getElementById(target).classList.toggle('font-bold')
				targetDiv.classList.replace("animate-productDetailIn", "animate-productDetailOut")
			}
			else {
				document.getElementById(target).classList.toggle('font-bold')
				targetDiv.classList.replace("animate-productDetailOut", "animate-productDetailIn")
			}
		}

		if (target !== select) {
			if (select !== "") {
				if (document.getElementById(`${select}_`).classList.contains("animate-productDetailIn")) {
					document.getElementById(`${select}_`).classList.replace("animate-productDetailIn", "animate-productDetailOut")
				}
			}
			if (targetDiv.className === 'hidden') {
				targetDiv.classList.remove('hidden')
			}
			document.getElementById(target).classList.toggle('font-bold')
			targetDiv.classList.contains("animate-productDetailOut") ? (
				targetDiv.classList.replace("animate-productDetailOut", "animate-productDetailIn") 
			) : (
				targetDiv.classList.add("animate-productDetailIn") 
			)

			setSelect(target)
		}
	}

	return (
		<div className="w-full h-auto mb-5">

			<div className="text-xl 2xl:text-2xl font-extrabold pb-1 ">{name}</div>
			<div className="text-base 2xl:text-lg pb-7 pl-1 font-bold ">₩ {price.toLocaleString()}</div>

			{details && 
				<div className={`${detailStyle} ${select === "detail" ? "font-bold" : ""}`} 
					id="detail" onClick={e =>onClick(e)}>
					details

					<div id="detail_" className='animate-productDetailIn' onClick= {e => e.stopPropagation()} style={detailStyle_}>
						{Object.keys(details).map((elem, index) => {
							return (
								<div className="ml-1" key={index}>{elem} : {details[elem]}</div>
							)
						})}
					</div>
				</div>
			}
			

			{fit_sizing &&
				<div className={`${detailStyle} ${select === "fit_sizing" ? "font-bold" : ""}`} 
					id="fit_sizing" onClick={e => onClick(e)}>
					fit&sizing

					<div id="fit_sizing_" className="hidden" onClick= {e => e.stopPropagation()} style={detailStyle_}>
						{Object.keys(fit_sizing).map((elem, index) => {
							return (
								<div className="ml-1" key={index}>{elem} : {fit_sizing[elem]}</div>
							)
						})}
					</div>
				</div>
			}

			{/* <div className={`2xl:text-lg ${detailStyle} ${select === "fabric" ? "font-bold" : ""}`} 
				id="fabric" onClick={e => onClick(e)}>
				fabric
				<div id="fabric_" className="hidden" onClick= {e => e.stopPropagation()} style={detailStyle_}>
					{`- ${fabric}`}
				</div>
			</div> */}

			{/* <div className={`2xl:text-lg ${detailStyle} ${select === "shipping" ? "font-bold" : ""}`} 
				id="shipping" onClick={e => onClick(e)}>
				shipping

				<div id="shipping_" className="hidden" onClick= {e => e.stopPropagation()} style={detailStyle_}>
					{shipping.map((elem, index) => <div key={index}>- {elem}</div>)}
				</div>
			</div> */}
		</div>
	)
}
