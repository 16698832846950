import { useLocation, useNavigate } from "react-router-dom"
import { useEffect } from "react"
import { useFetch } from "../hooks/useFetch"
import useOAuth from "../hooks/useOAuth"

import video from "../asset/mainVideo.mp4"

const Main = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const { oAuthLogin } = useOAuth()
    const { api } = useFetch()

    useEffect(() => {
        params.has("provider") &&
        (async () => {
            try {
                const { data, error } = await api({
                    action: `${params.get("provider")}OAuth`,
                    params: {
                        code: params.get("code"),
                        state: params.get("state")
                    }
                })
                if (!error) {
                    console.log(data)
                    const accessToken = data.access_token
                    const secretToken = data.secret_token
                    oAuthLogin({ accessToken, secretToken})
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()

    }, [location.search])

    return (
        <div className="flex justify-center items-center h-screen w-screen overflow-hidden">
            <video className="w-full h-full object-cover"
                onClick={() => navigate('/products')}
                autoPlay muted>
                <source src={video} type="video/mp4" />
            </video>
        </div>
    )
}

export default Main