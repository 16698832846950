import { useNavigate, useLocation } from "react-router-dom"
import { useState } from "react"
import { ReactComponent as TextLogo } from '../../asset/LOGO.svg'
import { ReactComponent as TextLogoWhite} from '../../asset/LOGO_white.svg'
import { ReactComponent as ImageLogo } from '../../asset/symbol-gyp.svg'
import Modal from "./Modal"
import Menu from "./Menu"


const Navbar = () => {

    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState(false)
    const openModal = () => setIsOpen(!isOpen)
    const closeModal = () => setIsOpen(!isOpen)
    const location = useLocation(); // 현재 위치 가져오기


    return(
        <div className="w-full h-full">
            <header className="flex justify-between items-center text-center fixed w-full h-[10%] leading-[4rem] z-10 ">

                <div className="flex items-center w-[10%]">
                    {location.pathname === '/' ? (
                        <TextLogoWhite className="cursor-pointer" onClick={() => navigate('/')} />
                    ) : (
                        <TextLogo className="cursor-pointer" onClick={() => navigate('/')} />
                    )}
                </div>

                {/* <div className="flex items-center justify-center w-[8%]">
                    <ImageLogo className="cursor-pointer" onClick={() => navigate('/')} />
                </div> */}

                <div className="w-[10%]">
                    <Menu openModal={openModal}/>
                </div>

            </header>

            <Modal isOpen={isOpen} closeModal={closeModal} />
        </div>
    )
}

export default Navbar