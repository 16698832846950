import { Link } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import useOAuth from "../hooks/useOAuth";
import naver from "../asset/socialIcon/naver.png"
import kakao from "../asset/socialIcon/kakao.png"


const Login = ({ closeModal }) => {

    const { login } = useAuth()
    const { oAuthUrl } = useOAuth()

    const kakaoUrl = oAuthUrl("kakao")
    const naverUrl = oAuthUrl("naver")

    const onClick = async (e) => {
        e.preventDefault();

        const id = document.getElementById('id').value;
        const password = document.getElementById('password').value;

        try {
            const { success, error } = await login({ id, password });
            
            if (success) {
                alert("로그인 되었습니다.");
                closeModal();
            } else {
                alert("잘못된 계정정보입니다.");
            }
        } catch (err) {
            console.error(err);
        }
    };

    const onKeyPress = e => {
        if (e.key === "Enter") {
            onClick(e);
        };
    };

    return (
        <form className={`flex flex-col content-center mx-auto font-raleway justify-between my-3 p-5 w-[30%] 2xl:w-[23%] h-[80%] 2xl:h-[65%] z-10 bg-slate-100 border-gray-700 border-[1.5px]`}
            onSubmit={onClick} action="">

            <div className="flex flex-col content-center w-full">

                <h1 className="text-3xl mx-auto mt-10 mb-14 font-bold">Logo</h1>
                
                <input className="h-[15%] mx-10 mb-5 p-3 bg-gray-50 border-solid border-[1px] border-gray-400 focus:outline-none "
                    placeholder="ID" id="id" type="text" onKeyDown={onKeyPress} />

                <input className="h-[15%] mx-10 p-3 bg-gray-50 border-solid border-[1px] border-gray-400 focus:outline-none"
                    placeholder="Password" id="password" type="password" onKeyDown={onKeyPress} />

                <div className="flex justify-between mx-10 mt-5 text-sm text-right text-sky-700">
                    <Link className=" hover:underline hover:text-sky-800 " 
                        to="/signup" onClick={closeModal} >
                        Sign up
                    </Link>
                    <Link className=" hover:underline hover:text-sky-800" 
                        to="/account" onClick={closeModal} >
                        Forgot ID/PW
                    </Link>
                </div>

                <button className="mx-10 my-14 py-3 text-center text-base bg-gray-800 border-gray-400
                    border-solid border-[1px] text-white  hover:font-bold" type="submit">
                    <div className="transition-all ">
                        Login
                    </div>
                </button>

            </div>

            <div className="flex flex-col content-center w-full pb-24">
                
                <div className="w-full h-full flex flex-row justify-center pt-10">
                    <img className="w-10 h-10 mx-2 transition-all hover:scale-110" src={naver} alt="naver" onClick={() => window.location.href = naverUrl}/>
                    <img className="w-10 h-10 mx-2 transition-all hover:scale-110" src={kakao} alt="kakao" onClick={() => window.location.href = kakaoUrl}/>
                    {/* <img className="w-10 h-10 mx-2 transition-all hover:scale-110" src={google} alt="google"/> */}
                </div>

            </div>

        </form>
        
    );
}

export default Login