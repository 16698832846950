const Footer = () => {

    return (
        <footer className="w-full pb-16 px-4 text-center text-gray-500 text-xs font-nanum lg:hidden">
            <div className="border-t border-gray-300 pt-4">
                <p>상호: 뮤크섹슈얼 &nbsp; | &nbsp; 대표자: 안태형</p>
                <p>사업자 등록번호: 169-48-00621</p>
                <p>사업장 주소: 서울시 관악구 미성5길 14 101호</p>
                <p>전화번호: 010-2575-7041</p>
            </div>
        </footer>
    )
}

export default Footer