import { useState, useEffect } from 'react';
import { useFetch } from '../hooks/useFetch';
import { useParams } from 'react-router-dom';

export const Forgot = () => {

    const { api } = useFetch();
    const [tab, setTab] = useState('id'); // 'id' 또는 'password'
    const [formData, setFormData] = useState({});

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const findId = async () => {

        const formatPhoneNumber = (phoneNum) => {
            if (phoneNum.length === 11) {
                return `${phoneNum.slice(0, 3)}-${phoneNum.slice(3, 7)}-${phoneNum.slice(7, 11)}`;
            }
            return phoneNum;
        };

        const handleApiError = (error) => {
            if (Array.isArray(error)) {
                const domainError = error.find(
                    (err) => err.loc && err.loc.includes("email") && err.msg.includes("not a valid email address")
                );
        
                if (domainError) {
                    return "유효하지 않은 도메인입니다.";
                }
                return error.map((err) => err.msg).join(', ');
            }
        
            if (typeof error === 'string') {
                return error;
            }
        
            return "알 수 없는 오류가 발생했습니다.";
        };

        try {
            const { data, error } = await api({
                action: 'findId',
                params: {
                    name: formData.name,
                    phoneNum: formatPhoneNumber(formData.phoneNum),
                    email: formData.email,
                },
            });
            if (!error) {
                console.log(data.user_id)
                alert(`검색된 아이디는 ${data.user_id} 입니다.`)
            } else {
                alert(handleApiError(error))
            }
        } catch (err) {
            console.error(err);
        }
    };

    const findPassword = async () => {

        const handleApiError = (error) => {
            if (Array.isArray(error)) {
                const domainError = error.find(
                    (err) => err.loc && err.loc.includes("email") && err.msg.includes("not a valid email address")
                );
        
                if (domainError) {
                    return "유효하지 않은 도메인입니다.";
                }
                return error.map((err) => err.msg).join(', ');
            }
        
            if (typeof error === 'string') {
                return error;
            }
        
            return "알 수 없는 오류가 발생했습니다.";
        };

        try {
            const { data, error } = await api({
                action: 'findPassword',
                params: {
                    userId: formData.userId,
                    email: formData.email,
                },
            });
            if (!error) {
                alert("입력하신 이메일로 비밀번호 재설정 메일이 발송되었습니다.")
            } else {
                alert(handleApiError(error))
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen font-nanum">
            <div className="w-full h-[70lvh] min-h-[650px] max-w-xl p-12 shadow-sm border border-gray-500">
                {/* 제목 */}
                <h1 className="text-4xl font-bold text-center mb-16">Logo</h1>

                {/* 탭 전환 */}
                <div className="relative">
                    <div className="flex justify-center mb-20 space-x-8">
                        <button
                            className={`w-1/2 py-4 text-lg ${tab === 'id' ? 'font-bold' : ''}`}
                            onClick={() => setTab('id')}
                        >
                            아이디 찾기
                        </button>
                        <button
                            className={`w-1/2 py-4 text-lg ${tab === 'password' ? 'font-bold' : ''}`}
                            onClick={() => setTab('password')}
                        >
                            비밀번호 찾기
                        </button>
                    </div>
                    {/* 하단 선 */}
                    <div
                        className={`absolute bottom-0 w-1/3 h-1 bg-black transition-transform duration-300 transform
                            ${tab === 'id' ? '-translate-x-10 left-[15%]' : 'translate-x-10 left-[52%]'}`}
                    />
                </div>

                {/* 아이디 찾기 */}
                {tab === 'id' && (
                    <div>
                        <input
                            type="text"
                            name="name"
                            placeholder="이름"
                            value={formData.name || ''}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-500"
                        />
                        <input
                            type="text"
                            name="phoneNum"
                            placeholder="휴대폰번호"
                            value={formData.phoneNum || ''}
                            onChange={handleInputChange}
                            className="w-full mt-7 p-3 border border-gray-500"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="이메일"
                            value={formData.email || ''}
                            onChange={handleInputChange}
                            className="w-full mt-7 p-3 border border-gray-500"
                        />
                        <button
                            className="w-full py-3 mt-16 bg-slate-700 text-white hover:bg-slate-800 "
                            onClick={findId}
                        >
                            아이디 찾기
                        </button>
                    </div>
                )}

                {/* 비밀번호 찾기 */}
                {tab === 'password' && (
                    <div>
                        <input
                            type="text"
                            name="userId"
                            placeholder="아이디"
                            value={formData.userId || ''}
                            onChange={handleInputChange}
                            className="w-full p-3 border border-gray-500"
                        />
                        <input
                            type="email"
                            name="email"
                            placeholder="이메일"
                            value={formData.email || ''}
                            onChange={handleInputChange}
                            className="w-full mt-7 p-3 border border-gray-500"
                        />
                        <button
                            className="w-full py-3 mt-16 bg-slate-700 text-white hover:bg-slate-800 "
                            onClick={findPassword}
                        >
                            비밀번호 찾기
                        </button>
                    </div>
                )}

                <div className="flex justify-end mt-12">
                    <a href="/signup" className="text-sky-700 hover:text-sky-800 hover:underline">
                        sign up
                    </a>
                </div>
            </div>
        </div>
    );
};

export const ResetPassword = () => {
    const { api } = useFetch();
    const { token: rawToken } = useParams();
    const [token, setToken] = useState('');
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: ''
    });
    const [error, setError] = useState('');

    useEffect(() => {
        const extractedToken = rawToken.replace('token=', '');
        setToken(extractedToken);
    }, [rawToken]);

    const validatePassword = (password, confirmPassword) => {
        if (!password) {
            return '비밀번호는 필수 입력 사항입니다.';
        }

        if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(password)) {
            return '한글을 포함할 수 없습니다';
        }

        if (password.length < 8) {
            return '8자 이상이어야 합니다';
        }

        if (!/[A-Z]/.test(password)) {
            return '최소 한 개의 대문자가 포함되어야 합니다';
        }

        if (!/[0-9]/.test(password)) {
            return '최소 한 개의 숫자가 포함되어야 합니다';
        }

        if (!/[!@#$%^&*]/.test(password)) {
            return '최소 한 개의 특수문자가 포함되어야 합니다';
        }

        if (confirmPassword && password !== confirmPassword) {
            return '비밀번호가 일치하지 않습니다.';
        }

        return '';
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));

        const passwordError = validatePassword(
            name === 'password' ? value : formData.password,
            name === 'confirmPassword' ? value : formData.confirmPassword
        );

        setError(passwordError);
    };

    const handleResetPassword = async () => {
        const { password, confirmPassword } = formData;

        const passwordError = validatePassword(password, confirmPassword);
        if (passwordError) {
            setError(passwordError);
            return;
        }

        try {
            const { data, error } = await api({
                action: 'resetPassword',
                params: {
                    token: token,
                    newPassword: password,
                    confirmPassword: confirmPassword
                }
            });

            if (!error) {
                alert('비밀번호가 성공적으로 재설정되었습니다.');
            } else {
                console.error(error);
                setError(error);
            }
        } catch (err) {
            setError('알 수 없는 오류가 발생했습니다.');
            console.error(err);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center min-h-screen font-nanum">
            <div className="w-full h-[70vh] min-h-[650px] max-w-xl p-12 shadow-sm border border-gray-500">
                <h1 className="text-4xl font-bold text-center mb-16">Logo</h1>

                <div>
                    <input
                        type="password"
                        name="password"
                        placeholder="새 비밀번호"
                        value={formData.password}
                        onChange={handleInputChange}
                        className="w-full p-3 border border-gray-500"
                    />
                    <input
                        type="password"
                        name="confirmPassword"
                        placeholder="비밀번호 확인"
                        value={formData.confirmPassword}
                        onChange={handleInputChange}
                        className="w-full mt-7 p-3 border border-gray-500"
                    />
                    <div className="mt-4 text-red-500 text-sm" style={{ visibility: error ? 'visible' : 'hidden' }}>
                        {error || 'Placeholder'}
                    </div>
                    <button
                        className="w-full py-3 mt-16 bg-slate-700 text-white hover:bg-slate-800"
                        onClick={handleResetPassword}
                    >
                        비밀번호 재설정
                    </button>
                </div>
            </div>
        </div>
    );
};
