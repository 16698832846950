import { useState } from "react"
import { useFetch } from "../../hooks/useFetch"
import { useNavigate } from "react-router-dom"
import { useMediaQuery } from "react-responsive"

export const SelectArea = ({ item, isLoggedIn }) => {


	const { name, id, price, options, image_urls } = item
	const { api } = useFetch()
	const navigate = useNavigate()
	const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
	const [selectedOption, setSelectedOption] = useState("")
	const [productOptionsQuantities, setProductOptionsQuantities] = useState(
		Object.keys(options).reduce((acc, option) => {
			acc[option.toLowerCase().replace(' ', '')] = 0
			return acc
		}, {})
	)

	const addToCart = async ({ itemId, options }) => {

		const mappedOptions = Object.keys(options)
			.map(key => (
				options[key] > 0 ? {
					color_size: key,
					quantity: options[key]
				} : null
			))
			.filter(option => option !== null);

		console.log(mappedOptions)

		const { data, error } = await api({
			action: "setCart",
			params: {
				itemId: itemId,
				options: mappedOptions
			}
		})
		if (!error) {
			console.log(data)
		} else {
			console.error(error)
		}
	}

	return (
		<div>
			<select value={selectedOption} className="w-full h-8 mt-7 2xl:mt-10  border text-sm 2xl:text-base outline-none"
				onChange={e => {
					if (e.target.value !== "") {
						setSelectedOption(e.target.value)
						setProductOptionsQuantities({...productOptionsQuantities, [e.target.value]: 1})
					}
				}}>

				<option value="">Please choose an option</option>

				{Object.keys(options).map((option, index) => (
				<option key={index} value={option.toLowerCase().replace(' ', '')}>
					{option}
				</option>))}

			</select>

			<hr className="my-5 2xl:my-7"></hr>

			{Object.keys(productOptionsQuantities).map((option, index) => {
			return (
				<div key={index}>
					{productOptionsQuantities[option] > 0 ? (
					<div className="h-8 mb-3 2xl:mb-5 ">

						<div className="flex flex-row justify-between">
							<div className="flex flex-col">
							<div className="text-sm 2xl:text-base">{name}</div>
							<div className="text-xs 2xl:text-sm leading-[0.7rem]">-{option}</div>
						</div>

						<div className="w-14 flex flex-row my-auto text-sm 2xl:text-base">
							<button onClick={e => {
								if (productOptionsQuantities[e.target.id] > 0) 
									setProductOptionsQuantities({...productOptionsQuantities, [e.target.id]: productOptionsQuantities[e.target.id] - 1})
								}} id={option}>-</button>

							<div className="mx-auto">
								{productOptionsQuantities[option]}
							</div>

							<button onClick={e => {
								setProductOptionsQuantities({...productOptionsQuantities, [e.target.id]: productOptionsQuantities[e.target.id] + 1})}}
								id={option}>+</button>
						</div>

						<div className="flex flex-row justify-between w-32 my-auto text-sm 2xl:text-base">
							<div className="mr-3">₩ {(price * productOptionsQuantities[option]).toLocaleString("ko-KR")}</div>
							<div onClick={e => {setProductOptionsQuantities({...productOptionsQuantities, [e.target.id]: 0})}} 
								id={option} className="cursor-pointer">x</div>
						</div>

					</div>

					</div>
					) : null}
				</div>
			)})}

			<div className="flex flex-row justify-between mt-10 2xl:mt-14 2xl:text-lg">

				<div>Total</div>
				<div>
					₩ {(() => {
						let total = Object.values(productOptionsQuantities).reduce((sum, value) => {
							return sum + price * value;
						}, 0);
						return total.toLocaleString();
						})()}
				</div>

			</div>

			<div className="flex flex-row justify-between h-12 mt-10 2xl:mt-14 2xl:text-lg">

				<button className="w-full mr-1 bg-white border border-1 transition-all duration-300 hover:font-bold" 
					onClick={async () => {

						if (!isLoggedIn) {
							alert("로그인이 필요합니다.");
							return;
						}

						if (Object.values(productOptionsQuantities).every(quantity => quantity === 0)) {
							alert('적어도 하나의 옵션을 선택해주세요.')
						}
						else {
							await addToCart({ itemId: id, options: productOptionsQuantities })
							if(window.confirm('장바구니로 바로 이동하시겠습니까?')) {
								isMobile ? navigate('/cart') : navigate('/mypage#cart')
							}
						}}}>
					add to cart
				</button>

				<button className="w-full ml-1 bg-gray-800 text-white border border-1 transition-all duration-300 hover:font-bold" 
					onClick={() => {

						if (!isLoggedIn) {
							alert("로그인이 필요합니다.");
							return;
						}
						
						if (Object.values(productOptionsQuantities).every(quantity => quantity === 0)) {
							alert('적어도 하나의 옵션을 선택해주세요.');
						} else {
							const productsData = Object.entries(productOptionsQuantities)
							.filter(([option, quantity]) => quantity > 0) // 수량이 0이 아닌 항목만 필터링
							.map(([option, quantity]) => ({
								item_id: id,
								options: option,
								quantity: quantity,
								total_price: price * quantity,
								item: {
									item_name: name,
									image_urls: image_urls,
								}
							}));
							navigate('/order', {state: {
								_cartData: productsData,
								orderType: 'item',
							}});
						}
					}}
				>
					buy now
				</button>

			</div>
		</div>
	)
	}
