import { useState, useEffect } from "react";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Carousel = ({ images, isMobile }) => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const [isMouseOver, setIsMouseOver] = useState(false);
	const [touchStartX, setTouchStartX] = useState(null);
	const [touchMoveX, setTouchMoveX] = useState(0);
	const [isSwiping, setIsSwiping] = useState(false);

	const nextSlide = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === images.length - 1 ? 0 : prevIndex + 1
		);
		resetTouchState();
	};

	const prevSlide = () => {
		setCurrentIndex((prevIndex) =>
			prevIndex === 0 ? images.length - 1 : prevIndex - 1
		);
		resetTouchState();
	};

	const goToSlide = (index) => {
		setCurrentIndex(index);
		resetTouchState();
	};

	const resetTouchState = () => {
		setTouchStartX(null);
		setTouchMoveX(0);
		setIsSwiping(false);
	};

	const handleTouchStart = (e) => {
		setTouchStartX(e.targetTouches[0].clientX);
		setIsSwiping(true);
	};

	const handleTouchMove = (e) => {
		if (isSwiping) {
			setTouchMoveX(e.targetTouches[0].clientX - touchStartX);
		}
	};

	const handleTouchEnd = () => {
		setIsSwiping(false);

		const touchDifference = touchMoveX;

		if (touchDifference > 50) {
			prevSlide()
		} else if (touchDifference < -50) {
			nextSlide()
		} else {
			resetTouchState()
		}
	};

	if (!images) {
		return null;
	}

	return (
		<div
			className="relative overflow-hidden h-full"
			onMouseOver={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
			onTouchStart={isMobile ? handleTouchStart : null}
			onTouchMove={isMobile ? handleTouchMove : null}
			onTouchEnd={isMobile ? handleTouchEnd : null}
		>
			{(!isMobile || isMouseOver) && (
				<div>
					<button
						className="absolute top-1/2 left-4 px-3 py-1 duration-100 opacity-40 z-10 transform -translate-y-1/2 bg-slate-50 hover:opacity-80 text-gray-800 rounded-full"
						onClick={prevSlide}
					>
						<FontAwesomeIcon icon={faAngleLeft} size="2x" />
					</button>

					<button
						className="absolute top-1/2 right-4 px-3 py-1 duration-100 opacity-40 z-10 transform -translate-y-1/2 bg-slate-50 hover:opacity-80 text-gray-800 rounded-full"
						onClick={nextSlide}
					>
						<FontAwesomeIcon icon={faAngleRight} size="2x" />
					</button>
				</div>
			)}

			<div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2 z-10">
				{images.map((_, index) => (
					<button
						key={index}
						className={`w-2 h-2 rounded-full ${
							currentIndex === index ? "bg-gray-800" : "bg-gray-300 opacity-60"
						}`}
						onClick={() => goToSlide(index)}
					/>
				))}
			</div>

			<div className="flex h-full">
				{images.map((image, index) => (
					<img
						alt={`Slide ${index}`}
						key={index}
						className={`w-full h-full flex-shrink-0 transition-all duration-500 ${
							isSwiping ? "transition-none" : ""
						}`}
						style={{
							transform: `translateX(calc(-${
								currentIndex * 100
							}% + ${touchMoveX}px))`,
						}}
						src={image}
					/>
				))}
			</div>
		</div>
	);
};
