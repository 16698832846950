import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFetch } from "../hooks/useFetch";

const Account = () => {
    const { api } = useFetch();
    const [tab, setTab] = useState("id"); // 'id' 또는 'password'
    const [formData, setFormData] = useState({
        name: "",
        phoneNum: "",
        email: "",
        userId: "",
    });

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const formatPhoneNumber = (phoneNum) => {
        if (phoneNum.length === 11) {
            return `${phoneNum.slice(0, 3)}-${phoneNum.slice(3, 7)}-${phoneNum.slice(7, 11)}`;
        }
        return phoneNum;
    };

    const handleApiError = (error) => {
        if (Array.isArray(error)) {
            const domainError = error.find(
                (err) => err.loc && err.loc.includes("email") && err.msg.includes("not a valid email address")
            );

            if (domainError) {
                return "유효하지 않은 도메인입니다.";
            }
            return error.map((err) => err.msg).join(', ');
        }

        if (typeof error === 'string') {
            return error;
        }

        return "알 수 없는 오류가 발생했습니다.";
    };

    const findId = async () => {
        try {
            const { data, error } = await api({
                action: 'findId',
                params: {
                    name: formData.name,
                    phoneNum: formatPhoneNumber(formData.phoneNum),
                    email: formData.email,
                },
            });
            if (!error) {
                alert(`검색된 아이디는 ${data.user_id} 입니다.`);
            } else {
                alert(handleApiError(error));
            }
        } catch (err) {
            console.error(err);
            alert("아이디 찾기 요청 중 오류가 발생했습니다.");
        }
    };

    const findPassword = async () => {
        try {
            const { data, error } = await api({
                action: 'findPassword',
                params: {
                    userId: formData.userId,
                    email: formData.email,
                },
            });
            if (!error) {
                alert("입력하신 이메일로 비밀번호 재설정 메일이 발송되었습니다.");
            } else {
                alert(handleApiError(error));
            }
        } catch (err) {
            console.error(err);
            alert("비밀번호 찾기 요청 중 오류가 발생했습니다.");
        }
    };

    const handleTabChange = (tabName) => {
        setTab(tabName);
    };

    return (
        <div className="flex flex-col items-center justify-center font-nanum min-h-screen">
            <div className="w-full max-w-md px-8 py-10 bg-white">
                {/* 상단 로고 */}
                <h1 className="text-center text-3xl font-bold mb-8">FSO</h1>

                {/* 탭 전환 */}
                <div className="relative flex justify-around mb-10">
                    <button
                        onClick={() => handleTabChange("id")}
                        className={`py-2 w-1/2 text-center ${tab === "id" ? "font-bold" : "text-gray-500"}`}
                    >
                        아이디 찾기
                    </button>
                    <button
                        onClick={() => handleTabChange("password")}
                        className={`py-2 w-1/2 text-center ${tab === "password" ? "font-bold" : "text-gray-500"}`}
                    >
                        비밀번호 찾기
                    </button>

                    <div
                        className={`absolute bottom-0 h-0.5 bg-black transition-all duration-300`}
                        style={{
                            width: "30%",
                            left: tab === "id" ? "10%" : "60%",
                        }}
                    />
                </div>

                {/* 아이디 찾기 폼 */}
                {tab === "id" && (
                    <form className="space-y-6 mt-4">
                        <div>
                            <label className="block text-sm font-medium mb-2" htmlFor="name">
                                이름
                            </label>
                            <input
                                type="text"
                                id="name"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 bg-gray-50 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-2" htmlFor="phoneNum">
                                휴대폰번호
                            </label>
                            <input
                                type="text"
                                id="phoneNum"
                                name="phoneNum"
                                value={formData.phoneNum}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 bg-gray-50 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-2" htmlFor="email">
                                이메일
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 bg-gray-50 border rounded"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={findId}
                            className="w-full bg-slate-700 text-white py-3 rounded mt-6"
                        >
                            아이디 찾기
                        </button>
                    </form>
                )}

                {/* 비밀번호 찾기 폼 */}
                {tab === "password" && (
                    <form className="space-y-6 mt-4">
                        <div>
                            <label className="block text-sm font-medium mb-2" htmlFor="userId">
                                아이디
                            </label>
                            <input
                                type="text"
                                id="userId"
                                name="userId"
                                value={formData.userId}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 bg-gray-50 border rounded"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium mb-2" htmlFor="email">
                                이메일
                            </label>
                            <input
                                type="email"
                                id="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full px-4 py-2 bg-gray-50 border rounded"
                            />
                        </div>
                        <button
                            type="button"
                            onClick={findPassword}
                            className="w-full bg-slate-700 text-white py-3 rounded mt-6"
                        >
                            비밀번호 찾기
                        </button>
                    </form>
                )}

                {/* 하단 링크 */}
                {/* <div className="flex justify-between mt-10 text-sm">
                    <Link to="/signup" className="text-gray-800 hover:underline">
                        Sign up
                    </Link>
                    <Link to="/login" className="text-gray-800 hover:underline">
                        Login
                    </Link>
                </div> */}
            </div>
        </div>
    );
};

export default Account;
