import productImg from "../asset/1.jpeg";
import { useMediaQuery } from 'react-responsive'


const Collection = () => {


    const isMobile = useMediaQuery({ query: '(max-width: 767px)' });

        
    return (
        <section className="p-8 w-full">

            <div className={`${isMobile ? "w-4/5 py-20" : "w-3/5 2xl:w-[50%] py-40"} mx-auto`}>

                <img src={productImg} alt="productImg" className="mb-20"/>

                {/* 한글 설명 */}
                <h1 className="text-xl font-bold text-gray-800 text-center">FSO - Gypsophilia</h1>
                <p className="mt-8 text-gray-800">
                집소필리아는 안개꽃을 모티브로 한 캐릭터입니다.
                </p>
                <p className="mt-4 text-gray-800">
                여정을 함께할 동료들과 공동체 의식을 형성해가는 집소필리아의 순수하며 무한한 가능성을 표현했습니다.
                </p>
                <p className="mt-4 text-gray-800">
                쇄골이 드러나는 넥라인은 페미닌한 느낌을 주며 우주 속의 안개꽃이 떠있는 듯한 프린팅 디자인으로 자연과 우주의 신비로운 요소를 결합했습니다.
                </p>
                <p className="mt-4 text-gray-800">
                소재는 면 100%를 사용해 부드러운 촉감을 느껴볼 수 있는 FSO의 첫 번째 컬렉션입니다.
                </p>
        
                {/* 영어 설명 */}
                <h2 className="mt-20 text-xl font-bold text-gray-800 text-center">FSO - Gypsophilia</h2>
                <p className="mt-8 text-gray-600">
                Gypsophilia is a character with a motif of mist flowers.
                </p>
                <p className="mt-4 text-gray-600">
                It expresses the pure and infinite possibilities of Gypsophilia forming a sense of community with colleagues who will share the journey.
                </p>
                <p className="mt-4 text-gray-600">
                The collarbone-exposed neckline gives a feminine feeling and combines the mysterious elements of nature and space with a printed design that seems to float fog flowers in space.
                </p>
                <p className="mt-4 text-gray-600">
                The material is FSO's first collection that uses 100% cotton to feel the soft touch.
                </p>
            </div>
        </section>
    );

}

export default Collection