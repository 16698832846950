import { Route, Routes } from "react-router-dom"

import Layout from "./components/layout/Layout"
import Main from "./pages/Main"
import Products from "./pages/Products"
import Product from "./pages/Product"
import Notice from "./pages/Notice"
import QnaList from "./pages/QnaList"
import QnaDetail from "./pages/QnaDetail"
import QnaCreate from "./pages/QnaCreate"
import SignUp from "./pages/SignUp"
import { Forgot, ResetPassword } from "./pages/Account"
import MyPage from "./pages/MyPage"
import Coupon from "./pages/Coupon"
import AccountInfoEdit from "./pages/AccountInfoEdit"
import Order from "./pages/Order"
import { Success, Fail } from "./components/common/OrderResponse"
import Policy from "./pages/Policy"
import Collection from "./pages/Collection"
import Contact from "./pages/Contact"
import About from "./pages/About"


const DesktopRoutes = () => {

	return (
		<Routes>
			<Route element={<Layout />}>
				<Route path="/" element={<Main />}/>
				<Route path="/policy" element={<Policy />} />
				<Route path="/mypage" element={<MyPage />} />
				<Route path="/signup" element={<SignUp />} />
				<Route path="/account" element={<Forgot />} />
				<Route path="/find-password/:token" element={<ResetPassword />} />
				<Route path="/edit" element={<AccountInfoEdit />} />
				<Route path="/coupon" element={<Coupon />} />

				<Route path="/service" element={<QnaList />} />
				<Route path="/service/qna" element={<QnaList />} />
				<Route path="/service/notice/:id" element={<QnaDetail />} />
				<Route path="/service/contents/:id" element={<QnaDetail />} />
				<Route path="/service/write" element={<QnaCreate />} />
				<Route path="/service/notice" element={<Notice />} />

				<Route path="/products" element={<Products />}/>
				<Route path="/product/:id" element={<Product />} />

				<Route path="/order" element={<Order />} />
				<Route path="/order/success" element={<Success />} />
                <Route path="/order/fail" element={<Fail />} />

				<Route path="/collection" element={<Collection />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />

			</Route>
		</Routes>
	)
}

export default DesktopRoutes
