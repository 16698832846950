import React, { useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const Notice = () => {
    const refs = useRef({});
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const hash = location.hash.substring(1);
        if (hash && refs.current[hash]) {
            refs.current[hash].scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
    }, [location.hash]);

    const items = [
        { 
            title: "signin", 
            contents: `
                메뉴를 통해 이용약관, 개인정보보호정책 동의 및 일정 양식의 가입항목을 기입함으로써 회원에 가입되며, 가입 즉시 서비스를 무료로 이용하실 수 있습니다.
                주문하실 때에 입력해야하는 각종 정보들을 일일이 입력하지 않으셔도 됩니다.
            ` 
        },
        { 
            title: "order", 
            contents: `
                상품주문은 다음단계로 이루어집니다.

                Step1: 상품검색
                Step2: 장바구니에 담기
                Step3: 회원ID 로그인
                Step4: 주문서 작성
                Step5: 결제방법선택 및 결제
                Step6: 주문 성공 화면 (주문번호)
            ` 
        },
        { 
            title: "payment", 
            contents: `
                FSO의 결제 시스템은 토스페이먼츠를 사용하고 있으며 
                카드 결제, 휴대폰 결제, 계좌이체, 무통장입금 등 다양한 결제 수단을 제공하고 있습니다.
            ` 
        },
        { 
            title: "shipping", 
            contents: `
                택배사는 한진원클릭택배를 이용하고 있습니다.
                상품은 오후 1시 이전 결제확인까지 당일출고가 이뤄지고 있으며, 출고뒤 지역에 따라 평균 1~3일 배송이 소요되고있습니다.
                도서 및 산간지역의 경우 추가 요금이 발생합니다.
            ` 
        },
        { 
            title: "exchange", 
            contents: `
                교환 및 반품 주소:
                서울특별시 관악구 미성5길 14 월드맨션 101호

                교환 및 반품이 가능한 경우:
                계약내용에 관한 서면을 받은 날부터 7일 이내로 교환/반품이 가능합니다.

                교환 및 반품이 불가능한 경우:
                - 이용자에게 책임 있는 사유로 상품이 멸실 또는 훼손된 경우(다만, 상품의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)
                - 이용자의 사용 또는 일부 소비에 의하여 상품의 가치가 현저히 감소한 경우

                ※ 고객님의 마음이 바뀌어 교환, 반품을 하실 경우 상품반송 비용은 고객님께서 부담하셔야 합니다. (색상 교환, 사이즈 교환 등 포함)
            ` 
        },
        { 
            title: "refund", 
            contents: `
                휴대폰 결제의 경우 통신사 정책으로 결제 발생 당월에만 취소가 가능합니다.
                
                - 가상계좌 건은 실시간으로 환불 처리가 불가하며, 영업일 기준 2~3일 소요 될 수 있습니다.
                - 체크 카드 거래 건 취소는 최대 10일 정도가 소요되나, 전액 취소의 경우 취소 요청 당일 환불 처리됩니다.
                - 계좌이체 건 환불 가능 기한은 180일로 모든 거래가 실시간 환불이 진행됩니다. 단, 심사 결과에 따라 환불 가능 기한은 축소될 수 있습니다. 또한 계좌이체 취소 건에 대해서 원거래 수수료는 반환 되지 않는 점 참고 부탁드립니다.
            ` 
        },
        { 
            title: "etc", 
            contents: `
                이용기간:
                주문으로 발생한 적립금은 배송완료 체크시점으로 부터 20일이 지나야 실제 사용 가능 적립금으로 변환됩니다. 20일 동안은 미가용 적립금으로 분류 됩니다. 미가용 적립금은 반품, 구매취소 등을 대비한 실질적인 구입이 되지 않은 주문의 적립금 입니다. 사용가능한 적립금(총 적립금-사용된적립금-미가용적립금)은 상품구매시 즉시 사용하실 수 있습니다.

                이용조건:
                적립금사용시 최소구매가능적립금(구매가능한 적립금 요구선)은 KRW 10,000 입니다. 적립금 사용시 최대구매가능적립금(적립금 1회 사용 가능 최대금액)은 '한도제한없음' 입니다.

                소멸조건:
                주문취소/환불시에 상품구매로 적립된 적립금은 함께 취소됩니다. 회원 탈퇴시에는 적립금은 자동적으로 소멸됩니다. 최종 적립금 발생일로부터 3년 동안 추가적립금 누적이 없을 경우에도 적립금은 소멸됩니다.
            ` 
        },
    ];

    const NoticeItem = ({ item }) => {
        const { title, contents } = item;

        const itemStyle = (id) =>
            `text-center w-[15%] py-4 2xl:text-lg cursor-pointer whitespace-nowrap ${
                id === title ? 'font-bold border-[2px] border-gray-500 border-b-white  min-w-[110px]' : 'border-b-[2px] border-gray-500'
            }`;

        const onClick = e => {
            navigate(`/service/notice#${e.target.id.split('_')[0]}`);
        };

        return (
            <div ref={(el) => (refs.current[title] = el)} id={title}>
                <div className="flex flex-row justify-between items-center pt-24 px-2" onClick={onClick}>
                    <div className={itemStyle("signin")} id="signin_">
                        회원가입 {title === "signin" && " 안내"}
                    </div>
                    <div className={itemStyle("order")} id="order_">
                        주문 {title === "order" && " 안내"}
                    </div>
                    <div className={itemStyle("payment")} id="payment_">
                        결제 {title === "payment" && " 안내"}
                    </div>
                    <div className={itemStyle("shipping")} id="shipping_">
                        배송 {title === "shipping" && " 안내"}
                    </div>
                    <div className={itemStyle("exchange")} id="exchange_">
                        교환/반품 {title === "exchange" && " 안내"}
                    </div>
                    <div className={itemStyle("refund")} id="refund_">
                        환불 {title === "refund" && " 안내"}
                    </div>
                    <div className={itemStyle("etc")} id="etc_">
                        기타 {title === "etc" && " 안내"}
                    </div>
                </div>
                <div className="p-10 pb-60 whitespace-pre-line">{contents}</div>
            </div>
        );
    };

    return (
        <div className="h-full w-full pt-32">
            <div className="flex flex-col w-[70%] 2xl:w-[55%] mx-auto font-nanum">
                {items.map((item, index) => (
                    <NoticeItem item={item} key={index} />
                ))}
            </div>
        </div>
    );
};

export default Notice;
