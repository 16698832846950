import { useFetch } from "../hooks/useFetch";
import { useState, useEffect } from "react";

const AccountInfoUpdate = () => {
    const { api } = useFetch();
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        nickname: '',
        phoneNum0: '010',
        phoneNum1: '',
        phoneNum2: '',
        email: '',
        emailDomain: 'gmail.com',
        customEmail: '',
        agreeToSMS: false,
        agreeToEmailMarketing: false,
    });

    const [verification, setVerification] = useState({
        password: '',
        confirmPassword: '',
        nickname: '',
        phoneNum: '',
        email: '',
    });

    const [customEmailVisible, setCustomEmailVisible] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const { data, error } = await api({
                action: 'getUserInfo',
            });
            if (!error) {
                console.log(data);

                const phoneParts = data.phone_num ? data.phone_num.split('-') : ['010', '', ''];
                const emailParts = data.email ? data.email.split('@') : ['', 'gmail.com'];
                
                // 도메인을 자동 매핑하는 로직 (Others로 설정)
                let emailDomain = emailParts[1];
                let customEmail = '';
                if (emailDomain !== 'gmail.com' && emailDomain !== 'naver.com' && emailDomain !== 'daum.net') {
                    emailDomain = 'Others';
                    customEmail = emailParts[1]; // 커스텀 도메인 입력
                }

                setFormData({
                    ...formData,
                    nickname: data.nickname || '',
                    phoneNum0: phoneParts[0],
                    phoneNum1: phoneParts[1],
                    phoneNum2: phoneParts[2],
                    email: emailParts[0],
                    emailDomain: emailDomain,
                    customEmail: customEmail,
                    agreeToSMS: data.sns_confirm || false, // 실 데이터에 맞게 수정
                    agreeToEmailMarketing: data.email_confirm || false, // 실 데이터에 맞게 수정
                });

                if (emailDomain === 'Others') {
                    setCustomEmailVisible(true);
                }
            }
        };
        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { id, value, type, checked } = e.target;

        if (id === 'emailDomain') {
            setFormData({
                ...formData,
                emailDomain: value,
                customEmail: value === 'Others' ? formData.customEmail : '', // Others 선택시 기존 커스텀 도메인 유지
            });

            setCustomEmailVisible(value === 'Others'); // Others가 선택되면 커스텀 도메인 필드 보이기
        } else {
            setFormData({
                ...formData,
                [id]: type === 'checkbox' ? checked : value
            });
        }

        validateInput(id, value);
    };

    const validateInput = (id, value) => {
        let errorMessage = '';
        switch (id) {
            case 'password':
                if (/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(value)) {
                    errorMessage = '한글을 포함할 수 없습니다';
                } else if (value.length < 8) {
                    errorMessage = '8자 이상이어야 합니다';
                } else if (!/[A-Z]/.test(value)) {
                    errorMessage = '최소 한 개의 대문자가 포함되어야 합니다';
                } else if (!/[0-9]/.test(value)) {
                    errorMessage = '최소 한 개의 숫자가 포함되어야 합니다';
                } else if (!/[!@#$%^&*]/.test(value)) {
                    errorMessage = '최소 한 개의 특수문자가 포함되어야 합니다';
                }
                break;

            case 'confirmPassword':
                if (value !== formData.password) {
                    errorMessage = '비밀번호가 일치하지 않습니다';
                }
                break;
            case 'nickname':
                if (!value) {
                    errorMessage = '닉네임은 필수 입력 사항입니다';
                } else if (value.length < 2 || value.length > 12) {
                    errorMessage = '2~12자 이내로 입력하셔야 합니다';
                }
                break;
            case 'phoneNum1':
            case 'phoneNum2':
                if (!value) {
                    errorMessage = '전화번호는 필수 입력 사항입니다';
                } else if (!/^[0-9]+$/.test(value)) {
                    errorMessage = '숫자만 입력 가능합니다';
                }
                break;
            case 'email':
                if (!value) {
                    errorMessage = '이메일은 필수 입력 사항입니다';
                }
                break;
            default:
                break;
        }
        setVerification((prev) => ({ ...prev, [id]: errorMessage }));
        return errorMessage;
    };

    const onClick = async () => {
        const requiredFields = ['password', 'confirmPassword', 'nickname', 'phoneNum1', 'phoneNum2', 'email'];

        let isValid = true;

        requiredFields.forEach((field) => {
            if (validateInput(field, formData[field]) || !formData[field]) {
                isValid = false;
            }
        });

        if (!isValid) {
            console.error('All fields are required');
            return;
        }

        const {
            password,
            email,
            nickname,
            phoneNum1,
            phoneNum2,
            customEmail,
            emailDomain,
            agreeToSMS,
            agreeToEmailMarketing,
        } = formData;

        const { data, error } = await api({
            action: 'updateUserInfo',
            params: {
                password: password,
                nickname: nickname,
                email: emailDomain === 'Others' ? `${email}@${customEmail}` : `${email}@${emailDomain}`,
                phone_num: `010-${phoneNum1}-${phoneNum2}`,
                agreeToSMS: agreeToSMS,
                agreeToEmailMarketing: agreeToEmailMarketing,
            }
        });

        if (error) {
            console.error(error);
        } else {
            console.log('User information updated:', data);
        }
    };

    const elemDivStyle = `mb-2 h-20 flex flex-col items-left justify-between`;
    const labelStyle = `flex flex-row justify-between h-7`;
    const inputStyle = `p-2 border border-gray-500 placeholder:italic h-12`;

    return (
        <div className="w-full h-[160vh] 2xl:h-[135vh] min-h-[1600px] pt-28 2xl:pt-32 2xl:pb-10 flex flex-row justify-center bg-white font-nanum">
            <div className="flex flex-col justify-start w-[43%] 2xl:w-[33%] h-[85%] 2xl:h-[75%] bg-white px-5 py-3 lg:p-16 shadow-md border-gray-500 border-2">
                <h1 className="text-2xl lg:text-3xl font-bold mb-10 2xl:mb-14 text-center font-raleway">Account Info Update</h1>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="password" className="text-gray-700">비밀번호</label>
                        {verification.password && <div className="text-red-500 text-sm">{verification.password}</div>}
                    </div>
                    <input
                        id="password"
                        type="password"
                        placeholder="비밀번호"
                        value={formData.password}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                        autoComplete="new-password"
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="confirmPassword" className="text-gray-700">비밀번호 확인</label>
                        {verification.confirmPassword && <div className="text-red-500 text-sm">{verification.confirmPassword}</div>}
                    </div>
                    <input
                        id="confirmPassword"
                        type="password"
                        placeholder="비밀번호 확인"
                        value={formData.confirmPassword}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                        autoComplete="new-password"
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="nickname" className="text-gray-700">닉네임</label>
                        {verification.nickname && <div className="text-red-500 text-sm">{verification.nickname}</div>}
                    </div>
                    <input
                        id="nickname"
                        placeholder="닉네임"
                        value={formData.nickname}
                        onChange={handleChange}
                        className={`${inputStyle}`}
                    />
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label className="text-gray-700">전화번호</label>
                        {(verification.phoneNum1 || verification.phoneNum2) && <div className="text-red-500 text-sm">{verification.phoneNum1 || verification.phoneNum2}</div>}
                    </div>
                    <div className={`flex flex-row justify-between h-[60%] items-center`}>
                        <input
                            id="phoneNum0"
                            value={formData.phoneNum0}
                            maxLength={3}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="mx-2">-</span>
                        <input
                            id="phoneNum1"
                            placeholder="0000"
                            value={formData.phoneNum1}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="mx-2">-</span>
                        <input
                            id="phoneNum2"
                            placeholder="0000"
                            value={formData.phoneNum2}
                            maxLength={4}
                            onChange={handleChange}
                            className={`w-[30%] text-center p-2 border border-gray-500 placeholder:italic h-full`}
                        />
                    </div>
                </div>

                <div className={`${elemDivStyle}`}>
                    <div className={`${labelStyle}`}>
                        <label htmlFor="email" className="text-gray-700">이메일</label>
                        {verification.email && <div className="text-red-500 text-sm">{verification.email}</div>}
                    </div>
                    <div className={`flex flex-row justify-between h-[60%] items-center`}>
                        <input
                            id="email"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            className={`w-[35%] text-left p-2 px-2 border border-gray-500 placeholder:italic h-full`}
                        />
                        <span className="text-gray-700 mx-1 my-auto 2xl:text-lg">@</span>
                        {customEmailVisible ? (
                            <input
                                id="customEmail"
                                placeholder="Other domain"
                                value={formData.customEmail}
                                onChange={handleChange}
                                className="w-[30%] p-2 mr-1 border border-gray-500 placeholder:italic h-full"
                            />
                        ) : (
                            <input
                                id="emailDomain"
                                value={formData.emailDomain}
                                disabled
                                className="w-[30%] p-2 mr-1 border border-gray-500 placeholder:italic h-full"
                            />
                        )}
                        <select
                            id="emailDomain"
                            value={formData.emailDomain}
                            onChange={handleChange}
                            className="flex border border-gray-500 w-[30%] p-2 h-full"
                        >
                            <option value="gmail.com">gmail.com</option>
                            <option value="naver.com">naver.com</option>
                            <option value="daum.net">daum.net</option>
                            <option value="Others">Others</option>
                        </select>
                    </div>
                </div>

                <div className="mt-5 flex flex-col items-start">
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToSMS"
                            checked={formData.agreeToSMS}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToSMS" className="text-sm text-gray-500">SMS 마케팅 정보 수신을 동의합니다. (선택)</label>
                    </div>
                    <div className="flex flex-row mt-1">
                        <input
                            type="checkbox"
                            id="agreeToEmailMarketing"
                            checked={formData.agreeToEmailMarketing}
                            onChange={handleChange}
                            className="mr-2"
                        />
                        <label htmlFor="agreeToEmailMarketing" className="text-sm text-gray-500">이메일 마케팅 정보 수신을 동의합니다. (선택)</label>
                    </div>
                </div>

                <button
                    onClick={onClick}
                    className="w-full p-2 bg-slate-700 text-white hover:bg-slate-800 mt-10 2xl:mt-14"
                >
                    정보 수정
                </button>
            </div>
        </div>
    );
};

export default AccountInfoUpdate;

