import React, { useEffect, useState } from 'react';
import { useFetch } from '../hooks/useFetch';

const CouponComponent = () => {
    const { api } = useFetch();
    const [ownedCoupons, setOwnedCoupons] = useState([]);
    const [availableCoupons, setAvailableCoupons] = useState([]);

    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const [ownedCouponsResponse, availableCouponsResponse] = await Promise.all([
                    api({ action: "getUserCoupon" }),
                    api({ action: "getCoupon" })
                ]);

                if (ownedCouponsResponse && availableCouponsResponse) {
                    if (!ownedCouponsResponse.error) {
                        console.log(ownedCouponsResponse.data);
                        setOwnedCoupons(ownedCouponsResponse.data);
                    } else {
                        console.error(ownedCouponsResponse.error);
                    }

                    if (!availableCouponsResponse.error) {
                        console.log(availableCouponsResponse.data);
                        setAvailableCoupons(availableCouponsResponse.data);
                    } else {
                        console.error(availableCouponsResponse.error);
                    }
                } else {
                    console.error("Invalid response from API");
                }
            } catch (err) {
                console.error("Error fetching coupons", err);
            }
        };

        fetchCoupons();
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'short', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('ko-KR', options);
    };

    const getDiscountInfo = (coupon) => {
        if (coupon.type === 'PRICE') {
            return `${coupon.discount_price}원 할인`;
        }
        if (coupon.type === 'RATIO') {
            return `${coupon.discount_ratio}% 할인`;
        }
        return '';
    };

    return (
        <div className="max-w-[70%] min-h-screen mx-auto pt-[15lvh] p-10 bg-white font-nanum">
            <div className="flex justify-between space-x-20">
                
                {/* 보유 쿠폰 섹션 */}
                <div className="w-1/2">
                    <div className="flex justify-between h-14 mb-7">
                        <div className="text-xl font-semibold">보유 쿠폰 ({ownedCoupons.length}장)</div>
                        {/* <button className="h-10 bg-slate-700 text-white py-2 px-4 mr-2 rounded">+ 쿠폰 등록</button> */}
                    </div>
                    <div className="space-y-4">
                        {ownedCoupons.map((coupon) => (
                            <div key={coupon.coupon_id} className="border border-gray-300 rounded-lg p-7 flex justify-between items-center">
                                <div>
                                    <div className="text-lg font-bold">{coupon.name}</div>
                                    <div className="text-gray-500">{getDiscountInfo(coupon)}</div>
                                    <div className="text-gray-400">
                                        {formatDate(coupon.started_at)} ~ {formatDate(coupon.finished_at)}
                                    </div>
                                </div>
                                {/* <button className="bg-slate-600 text-white py-2 px-4 rounded">적용 가능 상품</button> */}
                            </div>
                        ))}
                    </div>
                </div>

                {/* 취득 가능한 쿠폰 섹션 */}
                <div className="w-1/2">
                    <div className="flex justify-between h-14 mb-7">
                        <div className="text-xl font-semibold">수령 가능한 쿠폰 ({availableCoupons.length}장)</div>
                    </div>
                    <div className="space-y-5">
                        {availableCoupons.map((coupon) => (
                            <div key={coupon.coupon_id} className="border border-gray-300 rounded-lg p-7 flex justify-between items-center">
                                <div>
                                    <div className="text-lg font-bold">{coupon.name}</div>
                                    <div className="text-gray-500">{getDiscountInfo(coupon)}</div>
                                    <div className="text-gray-400">
                                        {formatDate(coupon.started_at)} ~ {formatDate(coupon.finished_at)}
                                    </div>
                                </div>
                                <button 
                                    className="bg-slate-700 text-white py-2 px-4 rounded"
                                    onClick={async () => {
                                        await api({
                                            action: "setUserCoupon",
                                            params: {
                                                couponId: coupon.coupon_id
                                            }
                                        })
                                        window.location.reload();
                                    }}
                                >
                                    쿠폰 받기
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CouponComponent;
