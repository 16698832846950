import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useFetch } from "../hooks/useFetch"
import { useSelector } from "react-redux"
import { Carousel } from "../components/common/Carousel"
import { DetailInfo } from "../components/common/DetailInfo"
import { SelectArea } from "../components/common/SelectArea"


const Product = () => {

	const { api } = useFetch()
	const location = useLocation()
	const pathSegments = location.pathname.split('/');
	const productId = pathSegments[pathSegments.length - 1];
	const [item, setItem] = useState()
	const [isLoading, setIsLoading] = useState(true)

	const { isLoggedIn } = useSelector(state => ({
        isLoggedIn: state.auth.isLoggedIn,
    }));

	useEffect(() => window.scroll(0, 0), [])

	useEffect(() => {
		(async () => {
			try {
				const { data, error } = await api({
					action: "getItem",
					query: `${productId}`
				});

				if (error) {
					console.error(error);
				} else {
					setItem(data)
				}
			} catch (err) {
				console.error(err);
			} finally {
				setIsLoading(false)
			}
		})();
	}, [productId])

	return(
		<div className="flex flex-col pb-[20lvh]">

			<div className="flex justify-center w-full 2xl:w-[90%] h-screen flex-wrap pt-20 2xl:pt-32 pd-10 m-auto animate-componentFadeIn font-nanum">

				{isLoading ? (
					<div>
						Loading...
					</div>
				): (
					<>
					<div className="w-1/3 h-[90%] m-12">
						<Carousel images={item.image_urls} />
					</div>

					<div className="w-1/3 m-16">
						<DetailInfo className="" item={item} />
						<SelectArea item={item} isLoggedIn={isLoggedIn}/>
					</div>
					</>
				)}

			</div>

			<div className="flex-col m-auto w-3/4 mt-24 font-nanum">
				<hr className=" bg-black border mx-auto"/>
				
				<div className=" flex flex-col justify-center w-[55%] overflow-hidden mx-auto pt-5">
					{item?.image_urls.map((elem, key) => {
						return (
							<img className="my-5" src={elem} alt="product" key={key}/>
						)
					})}

					<div style={{ whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
						{item?.description}
					</div>
				</div>
				
			</div>

		</div>
	)
}


export default Product
