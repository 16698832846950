import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useFetch } from "../hooks/useFetch";
import useOAuth from "../hooks/useOAuth"
import productImg from "../asset/1.jpeg";
import { Carousel } from "../components/common/Carousel"
import mobile1 from "../asset/mobile1.jpeg"
import mobile2 from "../asset/mobile2.jpeg"
import mobile3 from "../asset/mobile3.jpeg"
import mobile4 from "../asset/mobile4.jpeg"
import mobile5 from "../asset/mobile5.jpeg"

const Main = () => {
    
    const navigate = useNavigate();
    const location = useLocation()
    const params = new URLSearchParams(location.search)
    const { oAuthLogin } = useOAuth()
    const { api } = useFetch()

    useEffect(() => {
        params.has("provider") &&
        (async () => {
            try {
                const { data, error } = await api({
                    action: `${params.get("provider")}OAuth`,
                    params: {
                        code: params.get("code"),
                        state: params.get("state")
                    }
                })
                if (!error) {
                    console.log(data)
                    const accessToken = data.access_token
                    const secretToken = data.secret_token
                    oAuthLogin({ accessToken, secretToken})
                } else {
                    console.error(error)
                }
            } catch (err) {
                console.error(err)
            }
        }) ()

    }, [location.search])

    return (
        <div className=" pt-10">
            {/* <img src={productImg} alt="product" className="w-full h-auto" /> */}
            <Carousel images={ [mobile1, mobile2, mobile3, mobile4, mobile5] } isMobile={true} />
        </div>
    );
}

export default Main;
