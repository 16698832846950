import { Outlet } from "react-router-dom"
import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useAuth } from "../../hooks/useAuth"
import Navbar from "./Navbar"
import Sidebar from "./Sidebar"
import Footer from "./Footer"

const Layout = () => {

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const { fetchUserFromToken } = useAuth()
    
    useEffect(()=> {
        if (!isLoggedIn) {
            (async () => await fetchUserFromToken()) ();
        }
    }, [])

    return (
        <div>
            <Navbar />
            <Sidebar />
            <Outlet />
            <Footer />
        </div>
    )
}

export default Layout