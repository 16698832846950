import Cookies from 'js-cookie';

// localStorage
export const local = {

    get: (token) => {
        return localStorage.getItem(token)
    },
    set: (type, token) => {
        localStorage.setItem(type, token)
    },
    remove: (token) => {
        localStorage.removeItem(token)
    }
}

// cookies
export const cookies = {

    get: (token) => {
        return Cookies.get(token)
    },
    set: (type, token) => {
        Cookies.set(type, token, {
            path: '/',
            expires: 7
        })
    },
    remove: (token) => {
        Cookies.remove(token)
    }
}
