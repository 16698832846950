import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { loadTossPayments } from "@tosspayments/tosspayments-sdk";
import { v4 as uuidv4 } from 'uuid';
import { useFetch } from "../hooks/useFetch";

const OrderSummary = ({ items }) => (
    <div className="border-b py-6">
        {items.map((item, index) => (
            <div key={index} className="flex justify-between mb-4 pt-4 border-t">
                <div className="flex">
                    <img src={item.item.image_urls[0]} alt={item.item.item_name} className="w-24 h-24 object-cover rounded mr-4" />
                    <div>
                        <p className="font-semibold">{item.item.item_name}</p>
                        <p className="text-sm text-gray-600">옵션: {item.options}</p>
                        <p className="text-sm text-gray-600">{item.quantity}개 / ₩{item.total_price.toLocaleString()}원</p>
                    </div>
                </div>
            </div>
        ))}
        <div className="flex justify-between mt-4 pt-6 border-t">
            <p className="font-semibold">상품 합계</p>
            <p className="font-semibold">₩{items.reduce((sum, item) => sum + item.total_price, 0).toLocaleString()}원</p>
        </div>
    </div>
);

const Order = () => {
    const location = useLocation();
    const { api } = useFetch();
    const { state } = location;
    const { _cartData, orderType } = state;

    const [cartData, setCartData] = useState(_cartData);
    const [recipientName, setRecipientName] = useState("");
    const [phoneNum, setPhoneNum] = useState("");
    const [address, setAddress] = useState("");
    const [postCode, setPostCode] = useState("");
    const [detailAddress, setDetailAddress] = useState("");
    const [requirements, setRequirements] = useState("");
    const [userOrderData, setUserOrderData] = useState({
        item: [],
        totalPrice: "",
        merchantId: "",
    });
    const [loading, setLoading] = useState(false);
    const [ownedCoupons, setOwnedCoupons] = useState([]);
    const [selectedCoupon, setSelectedCoupon] = useState(null);

    const paymentMethodWidgetRef = useRef(null); 
    const paymentWidgetRef = useRef(null); 

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const initializeWidget = async () => {
            try {
                let data = null;

                if (orderType === 'cart') {
                    const response = await api({
                        action: "initialCartOrder",
                        params: {
                            type: "cart",
                            cartId: cartData.map(item => item.cart_id),
                        },
                    });
                    data = response.data;
                } else if (orderType === 'item') {
                    const options = cartData.map(item => ({
                        item_id: item.item_id,
                        option: item.options,
                        quantity: item.quantity
                    }));

                    const response = await api({
                        action: "initialItemOrder",
                        params: {
                            type: "item",
                            options: options,
                        },
                    });
                    data = response.data;
                }

                if (!data) {
                    console.error('No data returned from API');
                    return;
                }

                setUserOrderData({
                    item: data.ordering_item,
                    totalPrice: data.total_price,
                    merchantId: data.merchant_id,
                });

                if (!paymentWidgetRef.current) {
                    const tossPayments = await loadTossPayments(process.env.REACT_APP_TOSS_CLIENT_KEY);
                    const customerKey = uuidv4();

                    const widgets = tossPayments.widgets({ customerKey });

                    widgets.setAmount({
                        currency: 'KRW',
                        value: data.total_price,
                    });

                    const paymentMethodWidget = await widgets.renderPaymentMethods({
                        selector: "#payment-widget",
                        variantKey: "DEFAULT",
                    });

                    await widgets.renderAgreement({
                        selector: "#agreement",
                        variantKey: "DEFAULT",
                    });

                    paymentMethodWidgetRef.current = paymentMethodWidget;
                    paymentWidgetRef.current = widgets;
                }
            } catch (err) {
                console.error('Error initializing widget:', err);
            }
        };

        initializeWidget();

        const fetchCoupons = async () => {
            try {
                const ownedCouponsResponse = await api({ action: "getUserCoupon" });
                if (!ownedCouponsResponse.error) {
                    setOwnedCoupons(ownedCouponsResponse.data);
                } else {
                    console.error(ownedCouponsResponse.error);
                }
            } catch (err) {
                console.error("Error fetching coupons", err);
            }
        };

        fetchCoupons();
    }, [cartData, orderType]);

    const handlePostcodeSearch = () => {
        new window.daum.Postcode({
            oncomplete: function (data) {
                const roadAddr = data.roadAddress; 
                const jibunAddr = data.jibunAddress; 
                const zonecode = data.zonecode; 

                setAddress(roadAddr || jibunAddr);
                setPostCode(zonecode);
            }
        }).open();
    };

    const getDiscountedPrice = () => {
        const totalPrice = userOrderData.totalPrice;
        if (selectedCoupon) {
            if (selectedCoupon.type === 'PRICE') {
                return Math.max(0, totalPrice - selectedCoupon.discount_price);
            }
            if (selectedCoupon.type === 'RATIO') {
                return Math.max(0, totalPrice * (1 - selectedCoupon.discount_ratio / 100));
            }
        }
        return totalPrice;
    };

    const onPurchase = async () => {
        try {
            const selectedPaymentMethod = await paymentMethodWidgetRef.current.getSelectedPaymentMethod();

            const { data, error } = await api({
                action: "setOrder",
                params: {
                    itemInfo: userOrderData.item,
                    merchantId: userOrderData.merchantId,
                    recipientName,
                    postCode,
                    address,
                    detailAddress,
                    phoneNum,
                    requirements,
                    paymentMethod: selectedPaymentMethod.code,
                    totalPrice: getDiscountedPrice(),
                    couponId: selectedCoupon ? selectedCoupon.coupon_id : ""
                }
            });

            if (!error) {
                const { data, error } = await api({
                    action: "setPayment",
                    params: {
                        paymentMethod: selectedPaymentMethod.code,
                        paymentName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                        merchantId: userOrderData.merchantId,
                        amount: getDiscountedPrice(),
                    }
                });

                if (!error) {
                    try {
                        if (paymentWidgetRef.current) {
                            await paymentWidgetRef.current.requestPayment({
                                orderId: userOrderData.merchantId,
                                orderName: `${userOrderData.item[0]} 외 ${userOrderData.item.length}건`,
                                customerName: recipientName,
                                successUrl: `${window.location.origin}/order/success`,
                                failUrl: `${window.location.origin}/order/fail`,
                            });
                        }
                    } catch (err) {
                        console.error('결제 요청 중 오류가 발생했습니다:', err);
                    }
                } else {
                    console.error(error)
                }
            }
        } catch (err) {
            console.error(err);
        }

        
    };

    const totalAmount = userOrderData.totalPrice;
    const discountAmount = totalAmount - getDiscountedPrice();
    const shippingFee = totalAmount >= 50000 ? 0 : 3000;
    const finalAmount = getDiscountedPrice() + shippingFee;

    return (
        <div className="container mx-auto p-6 font-nanum">
            <h1 className="text-2xl font-bold mb-8">주문 결제</h1>
            
            <OrderSummary items={cartData} />
            <div className="mt-8 pb-8">
                <h2 className="font-semibold text-xl mb-10">주문 정보</h2>
                <div className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium">이름</label>
                        <input
                            type="text"
                            value={recipientName}
                            onChange={(e) => setRecipientName(e.target.value)}
                            placeholder="이름"
                            className="w-full border rounded p-2 mt-1"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">연락처</label>
                        <input
                            type="text"
                            value={phoneNum}
                            onChange={(e) => setPhoneNum(e.target.value)}
                            placeholder="연락처"
                            className="w-full border rounded p-2 mt-1"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">주소</label>
                        <div className="flex items-center space-x-2">
                            <input
                                type="text"
                                value={address || ""}
                                placeholder="주소"
                                readOnly
                                className="border rounded p-2 flex-grow"
                            />
                            <button
                                className="bg-slate-700 text-white rounded p-2 flex-shrink-0"
                                onClick={handlePostcodeSearch}
                            >
                                주소 검색
                            </button>
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium">우편 번호</label>
                        <input
                            type="text"
                            value={postCode}
                            placeholder="우편 번호"
                            readOnly
                            className="w-full border rounded p-2 mt-1"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">상세 주소</label>
                        <input
                            type="text"
                            value={detailAddress}
                            onChange={(e) => setDetailAddress(e.target.value)}
                            placeholder="상세 주소"
                            className="w-full border rounded p-2 mt-1"
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium">배송 시 요청 사항</label>
                        <input
                            type="text"
                            value={requirements}
                            onChange={(e) => setRequirements(e.target.value)}
                            placeholder="요청 사항"
                            className="w-full border rounded p-2 mt-1"
                        />
                    </div>
                </div>
            </div>

            <div className="mt-4 py-8 border-t">
                <h3 className="text-lg font-semibold mb-4">보유 쿠폰 ({ownedCoupons.length}장)</h3>
                <div className="space-y-4">
                    {ownedCoupons.length > 0 ? (
                        ownedCoupons.map((coupon) => (
                            <div key={coupon.coupon_id} className="border border-gray-300 rounded-lg p-5 flex justify-between items-center">
                                <div>
                                    <div className="text-base font-semibold">{coupon.name}</div>
                                    <div className="text-sm text-gray-500">{coupon.type === 'PRICE' ? `${coupon.discount_price}원 할인` : `${coupon.discount_ratio}% 할인`}</div>
                                </div>
                                <button 
                                    className={`${selectedCoupon && selectedCoupon.coupon_id === coupon.coupon_id ? "bg-slate-500" : "bg-slate-700" } text-white py-2 px-4 rounded`}
                                    onClick={() =>
                                        setSelectedCoupon(
                                            selectedCoupon &&
                                            selectedCoupon.coupon_id === coupon.coupon_id
                                                ? null
                                                : coupon
                                        )
                                    }
                                >
                                    {selectedCoupon && selectedCoupon.coupon_id === coupon.coupon_id
                                        ? "선택취소"
                                        : "적용하기"}
                                </button>
                            </div>
                        ))
                    ) : (
                        <p className="min-h-32 flex items-center justify-center text-center text-gray-600 mb-4">
                            보유한 쿠폰이 없습니다.
                        </p>
                    )}
                </div>
            </div>

            <div>
                <hr className="my-10"/>
                <h2 className="font-semibold text-xl mb-14 ">결제 정보</h2>
                <div className="flex justify-between font-semibold ">
                    <span>총 결제 금액</span>
                    <span>₩{totalAmount.toLocaleString()}원</span>
                </div>
                <div className="flex justify-between mt-4 font-semibold">
                    <span>할인 금액</span>
                    <span>₩{discountAmount.toLocaleString()}원</span>
                </div>
                <div className="flex justify-between mt-4 font-semibold">
                    <span>배송비</span>
                    <span>₩{shippingFee.toLocaleString()}원</span>
                </div>
                <div className="flex justify-between mt-4 font-semibold">
                    <span>최종 결제 금액</span>
                    <span>₩{finalAmount.toLocaleString()}원</span>
                </div>
            </div>

            <div className="my-10">
                <div id="payment-widget" className="mb-4"></div>
                <div id="agreement" className="mb-4"></div>

                <button
                    onClick={onPurchase}
                    className={`w-full bg-slate-700 text-white py-3 rounded ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
                    disabled={loading}
                >
                    {loading ? '결제 중...' : `${finalAmount.toLocaleString()}원 결제하기`}
                </button>
            </div>
        </div>
    )
};

export default Order;

